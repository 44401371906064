import React, { createContext, useContext, useState } from 'react';
import { useAuth } from './AuthContext';

const ProgressContext = createContext();

export const ProgressProvider = ({ children }) => {
  const { user } = useAuth();
  const [conversationHistory, setConversationHistory] = useState([]);
  const [streaks, setStreaks] = useState({ current: 0, best: 0 });
  const [stats, setStats] = useState({
    totalConversations: 0,
    averageScore: 0,
    bestScore: 0,
    recentScores: [],
    strengthProgress: {
      conversationFlow: [],
      emotionalEngagement: [],
      listeningSkills: [],
      confidence: [],
      humor: []
    }
  });

  const addConversation = async (conversation) => {
    try {
      if (!user) {
        console.log('No user logged in');
        return;
      }

      const newHistory = [...conversationHistory, {
        id: Date.now(),
        date: new Date(),
        character: conversation.character,
        score: conversation.score,
        strengths: conversation.strengths,
        duration: conversation.duration,
        gameMode: conversation.gameMode
      }];

      setConversationHistory(newHistory);
      updateStats(newHistory);
      updateStreaks(newHistory);

      // Only update leaderboard for life bar mode
      if (conversation.gameMode === 'lifeBar') {
        try {
          await fetch(`/api/leaderboard/${user.id}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            body: JSON.stringify({
              score: Math.round(conversation.score), // Round the score
              category: 'overall'
            })
          });
        } catch (error) {
          console.error('Error updating leaderboard:', error);
        }
      }
    } catch (error) {
      console.error('Error saving progress:', error);
    }
  };

  const updateStats = (history) => {
    const scores = history.map(c => c.score);
    const averageScore = scores.length > 0 ? 
      scores.reduce((a, b) => a + b, 0) / scores.length : 0;
    const recentScores = history.slice(-5).map(c => c.score);

    setStats(prevStats => ({
      ...prevStats,
      totalConversations: history.length,
      averageScore: Number(averageScore.toFixed(1)),
      bestScore: scores.length > 0 ? Math.max(...scores) : 0,
      recentScores,
      strengthProgress: calculateStrengthProgress(history)
    }));
  };

  const updateStreaks = (history) => {
    const dates = history.map(c => new Date(c.date).toDateString());
    let currentStreak = 0;
    let bestStreak = 0;
    let lastDate = new Date();

    if (dates.length > 0) {
      dates.reverse().forEach((date) => {
        const diff = Math.floor((lastDate - new Date(date)) / (1000 * 60 * 60 * 24));
        
        if (diff <= 1) {
          currentStreak++;
          bestStreak = Math.max(bestStreak, currentStreak);
        } else {
          currentStreak = 1;
        }
        lastDate = new Date(date);
      });
    }

    setStreaks({ current: currentStreak, best: bestStreak });
  };

  const calculateStrengthProgress = (history) => {
    return {
      conversationFlow: history.map(c => ({
        date: c.date,
        score: c.strengths?.conversationFlow?.score || 0
      })),
      emotionalEngagement: history.map(c => ({
        date: c.date,
        score: c.strengths?.emotionalEngagement?.score || 0
      })),
      listeningSkills: history.map(c => ({
        date: c.date,
        score: c.strengths?.listeningSkills?.score || 0
      })),
      confidence: history.map(c => ({
        date: c.date,
        score: c.strengths?.confidence?.score || 0
      })),
      humor: history.map(c => ({
        date: c.date,
        score: c.strengths?.humor?.score || 0
      }))
    };
  };

  return (
    <ProgressContext.Provider value={{
      conversationHistory,
      stats,
      streaks,
      addConversation
    }}>
      {children}
    </ProgressContext.Provider>
  );
};

export const useProgress = () => {
  const context = useContext(ProgressContext);
  if (!context) {
    throw new Error('useProgress must be used within a ProgressProvider');
  }
  return context;
};

export default ProgressProvider;