// src/components/chat/hooks/useVoiceChat.js
import { useState, useRef, useEffect } from 'react';
import { useApp } from '../../../contexts/AppContext';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import Vapi from '@vapi-ai/web';
import { getVapiAssistantConfig } from '../../../config/vapiConfig';

export const useVoiceChat = (chatMode) => {
  const navigate = useNavigate();
  const { selectedCharacter, setMessages, gameMode } = useApp();
  const { authToken } = useAuth();
  const [isVoiceMode, setIsVoiceMode] = useState(false);
  const [isAssistantSpeaking, setIsAssistantSpeaking] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(0);
  const [error, setError] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const vapi = useRef(null);
  const messageIdRef = useRef(1);
  const initAttempts = useRef(0);

  // Add debug logging function
  const debugLog = (message, data = {}) => {
    console.group(`🎤 VoiceChat: ${message}`);
    console.log('State:', {
      chatMode,
      isInitialized,
      hasVapi: !!vapi.current,
      hasAuthToken: !!authToken,
      hasSelectedCharacter: !!selectedCharacter,
      error,
      isVoiceMode,
      isAssistantSpeaking,
    });
    if (Object.keys(data).length > 0) {
      console.log('Data:', data);
    }
    console.groupEnd();
  };

  // Add retry logic for initialization
  useEffect(() => {
    let isMounted = true;
    const maxAttempts = 3;
    const attemptInterval = 2000; // 2 seconds

    const initializeVapi = async () => {
      if (gameMode === 'lifebar') {
        debugLog('Skipping VAPI initialization in lifebar mode');
        return;
      }

      debugLog('Starting VAPI initialization', { authToken: !!authToken });

      if (!authToken) {
        debugLog('No auth token, waiting...');
        if (initAttempts.current < maxAttempts) {
          initAttempts.current++;
          setTimeout(initializeVapi, attemptInterval);
        } else {
          setError('Authentication failed. Please try logging in again.');
          navigate('/login');
        }
        return;
      }

      if (!selectedCharacter) {
        debugLog('Initialization failed - No character selected');
        navigate('/character');
        return;
      }

      try {
        const baseUrl = import.meta.env.PROD 
          ? 'https://api.gotgames.app' 
          : '';

        debugLog('Fetching VAPI token');
        const response = await fetch(`${baseUrl}/api/ai/vapi/token`, {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`Failed to get VAPI token: ${response.status}`);
        }

        const data = await response.json();
        debugLog('Token response received', { status: response.status });

        if (!data.token) {
          throw new Error('No token in response');
        }

        if (isMounted) {
          debugLog('Creating VAPI instance');
          vapi.current = new Vapi(data.token);
          
          // Start VAPI with config immediately after creation
          const assistantConfig = getVapiAssistantConfig(selectedCharacter);
          await vapi.current.start(assistantConfig);
          
          debugLog('VAPI instance created and started successfully');
          setIsInitialized(true);
          setError(null);
        }
      } catch (err) {
        debugLog('VAPI initialization failed', { error: err });
        setError(err.message || 'Failed to initialize voice chat');
      }
    };

    if (chatMode === 'voice' && !isInitialized) {
      initializeVapi();
    }

    return () => {
      isMounted = false;
    };
  }, [chatMode, authToken, selectedCharacter, isInitialized, navigate]);

  // Event handlers setup
  useEffect(() => {
    debugLog('Event handlers effect triggered');

    if (!vapi.current || !isInitialized || !selectedCharacter || chatMode !== 'voice') {
      debugLog('Event handlers setup skipped', {
        hasVapi: !!vapi.current,
        isInitialized,
        hasSelectedCharacter: !!selectedCharacter,
        chatMode
      });
      return;
    }

    const handleCallStart = () => {
      console.log('Call started');
      setIsVoiceMode(true);
    };

    const handleCallEnd = () => {
      console.log('Call ended');
      setIsVoiceMode(false);
    };

    const handleSpeechStart = () => {
      console.log('Assistant started speaking');
      setIsAssistantSpeaking(true);
    };

    const handleSpeechEnd = () => {
      console.log('Assistant stopped speaking');
      setIsAssistantSpeaking(false);
    };

    const handleVolumeLevel = (level) => setVolumeLevel(level);

    const handleMessage = (message) => {
      console.log('Voice message received:', message);
      if (!message?.text?.trim()) return;

      setMessages(prev => [...prev, {
        id: messageIdRef.current++,
        text: message.text,
        sender: message.type === 'transcript' ? 'user' : 'ai',
        ...(message.type === 'llm' && { character: selectedCharacter.name }),
      }]);
    };

    // Register event handlers
    console.log('Registering event handlers');
    vapi.current.on('call-start', handleCallStart);
    vapi.current.on('call-end', handleCallEnd);
    vapi.current.on('speech-start', handleSpeechStart);
    vapi.current.on('speech-end', handleSpeechEnd);
    vapi.current.on('volume-level', handleVolumeLevel);
    vapi.current.on('message', handleMessage);

    return () => {
      console.log('Cleaning up voice chat event handlers');
      if (vapi.current) {
        vapi.current.off('call-start', handleCallStart);
        vapi.current.off('call-end', handleCallEnd);
        vapi.current.off('speech-start', handleSpeechStart);
        vapi.current.off('speech-end', handleSpeechEnd);
        vapi.current.off('volume-level', handleVolumeLevel);
        vapi.current.off('message', handleMessage);
        
        try {
          vapi.current.stop();
        } catch (error) {
          console.error('Error stopping voice chat:', error);
        }
      }
    };
  }, [chatMode, selectedCharacter, isInitialized, setMessages]);

  const reinitialize = () => {
    debugLog('Reinitializing voice chat');
    setIsInitialized(false);
    setError(null);
    if (vapi.current) {
      try {
        vapi.current.stop();
        vapi.current = null;
        debugLog('Cleanup successful');
      } catch (error) {
        debugLog('Cleanup failed', { error });
      }
    }
  };

  return {
    isVoiceMode,
    isAssistantSpeaking,
    volumeLevel,
    error,
    isInitialized,
    vapiRef: vapi,
    reinitialize
  };
};