// useGameLogic.js
import { useRef, useCallback, useEffect } from 'react';
import { useApp } from '../../../contexts/AppContext';

export function useGameLogic() {
  const decayMultiplier = useRef(1.0);
  const lastResponseTime = useRef(Date.now());

  const {
    gameMode,
    lifeBar,
    setLifeBar,
    isGameActive,
    isCalculatingScore,
    analyzeAndUpdateLifeBar,
  } = useApp();

  const debugLog = (message, data = {}) => {
    console.group(`🎮 useGameLogic: ${message}`);
    console.log('State:', {
      gameMode,
      lifeBar,
      isGameActive,
      isCalculatingScore,
      lastResponseTime: lastResponseTime.current,
      decayMultiplier: decayMultiplier.current
    });
    if (Object.keys(data).length > 0) {
      console.log('Data:', data);
    }
    console.groupEnd();
  };

  // Life bar decay effect
  useEffect(() => {
    let decayTimer;
    if (gameMode === 'lifeBar' && isGameActive && !isCalculatingScore) {
      debugLog('Starting life bar decay timer');
      decayTimer = setInterval(() => {
        const timeSinceLastResponse = (Date.now() - lastResponseTime.current) / 1000;
        decayMultiplier.current = Math.pow(1.1, Math.floor(timeSinceLastResponse / 10));
        
        setLifeBar((prev) => {
          const newValue = Math.max(0, prev - 0.5 * decayMultiplier.current);
          debugLog('Updating life bar', { 
            previous: prev, 
            new: newValue, 
            decayMultiplier: decayMultiplier.current,
            timeSinceLastResponse 
          });
          return newValue;
        });
      }, 1000);
    }
    return () => {
      if (decayTimer) {
        debugLog('Cleaning up life bar decay timer');
        clearInterval(decayTimer);
      }
    };
  }, [gameMode, isGameActive, isCalculatingScore, setLifeBar]);

  const updateLastResponseTime = useCallback(() => {
    lastResponseTime.current = Date.now();
    decayMultiplier.current = 1.0;
    debugLog('Updated last response time');
  }, []);

  return {
    isGameActive,
    isCalculatingScore,
    lifeBar,
    analyzeAndUpdateLifeBar,
    updateLastResponseTime,
  };
}

export default useGameLogic;
