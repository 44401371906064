import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trophy, User, Heart, ChevronRight } from 'lucide-react';
import { AuthContext } from '../contexts/AuthContext';

const LandingScreen = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  return (
    <div className="h-screen bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500 p-6 flex flex-col">
      <div className="flex justify-between items-center">
        <Trophy
          className="text-white/90 cursor-pointer"
          size={24}
          onClick={() => navigate('/leaderboard')}
        />
        {user && user.email ? (
          <div
            className="flex items-center space-x-2 cursor-pointer"
            onClick={() => navigate('/profile')}
          >
            <div className="w-8 h-8 rounded-full bg-white/20 flex items-center justify-center">
              <span className="text-white text-sm">
                {user.email.charAt(0).toUpperCase()}
              </span>
            </div>
          </div>
        ) : (
          <User
            className="text-white/90 cursor-pointer"
            size={24}
            onClick={() => navigate('/login', { state: { from: '/' } })}
          />
        )}
      </div>

      <div className="flex-1 flex flex-col items-center justify-center text-center">
        <h1 className="text-7xl font-bold text-white mb-4">Rate Your Rizz</h1>
        <p className="text-xl text-white/90 mb-12">Chat with AI dates & see if you've got game</p>

        <div className="flex space-x-6 mb-12">
          <div
            className="bg-white/10 backdrop-blur-sm rounded-xl p-4 flex flex-col items-center cursor-pointer"
            onClick={() => {
              if (user) {
                navigate('/leaderboard');
              } else {
                navigate('/login', { state: { from: '/leaderboard' } });
              }
            }}
          >
            <Trophy className="text-yellow-300 mb-2" size={24} />
            <span className="text-white/80 text-sm">Leaderboard</span>
          </div>
          <div
            className="bg-white/10 backdrop-blur-sm rounded-xl p-4 flex flex-col items-center cursor-pointer"
            onClick={() => {
              if (user) {
                navigate('/gender');
              } else {
                navigate('/login', { state: { from: '/gender' } });
              }
            }}
          >
            <Heart className="text-pink-300 mb-2" size={24} />
            <span className="text-white/80 text-sm">Dates</span>
          </div>
        </div>

        <button
          onClick={() => {
            if (user) {
              navigate('/gender');
            } else {
              navigate('/login', { state: { from: '/gender' } });
            }
          }}
          className="bg-white text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 to-pink-500 rounded-full px-12 py-4 text-xl font-bold backdrop-blur-sm border border-white/20"
        >
          Start Chat <ChevronRight className="ml-2 text-pink-500 inline" />
        </button>
      </div>

      <div className="mt-auto">
        <div className="bg-white/10 backdrop-blur-sm rounded-xl p-4">
          <div className="flex justify-between items-center mb-4">
            <span className="text-white font-semibold">🔥 Top Rizz Today</span>
            <span
              className="text-white/60 text-sm cursor-pointer"
              onClick={() => {
                if (user) {
                  navigate('/leaderboard');
                } else {
                  navigate('/login', { state: { from: '/leaderboard' } });
                }
              }}
            >
              view all
            </span>
          </div>
          {[
            { name: "Alex", score: 9.8 },
            { name: "Jamie", score: 9.5 },
            { name: "Jordan", score: 9.2 }
          ].map((user, i) => (
            <div key={i} className="flex justify-between items-center py-2">
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full bg-gradient-to-r from-pink-500 to-indigo-500 flex items-center justify-center text-white font-bold">
                  {i + 1}
                </div>
                <span className="text-white ml-3">{user.name}</span>
              </div>
              <span className="text-white font-bold">{user.score}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LandingScreen;