// src/components/ModeSelectionScreen.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../contexts/AppContext';
import { motion } from 'framer-motion';
import { Clock, MessageSquare, Heart, Infinity, Zap } from 'lucide-react';

const ModeSelectionScreen = () => {
  const navigate = useNavigate();
  const { 
    setChatMode, 
    setGameMode, 
    setTimeLeft,
    setLifeBar,
    setMessageCount,
    setIsGameActive,
    setMessages
  } = useApp();

  const modes = [
    {
      id: 'timeTrial',
      title: 'Time Trial',
      description: '3 minutes to charm',
      icon: <Clock className="w-6 h-6 text-yellow-300" />,
      chatMode: 'text',
      gameMode: 'timeTrial',
      onSelect: () => {
        setTimeLeft(180);
        setMessageCount(0);
        setMessages([]);
        setIsGameActive(true);
      }
    },
    {
      id: 'lifeBar',
      title: 'Life Bar',
      description: 'Keep the charm alive',
      icon: <Heart className="w-6 h-6 text-pink-300" />,
      chatMode: 'text',
      gameMode: 'lifeBar',
      onSelect: () => {
        setLifeBar(100);
        setMessageCount(0);
        setMessages([]);
        setIsGameActive(true);
      }
    },
    {
      id: 'classic',
      title: 'Classic',
      description: '14 messages to prove yourself',
      icon: <MessageSquare className="w-6 h-6 text-blue-300" />,
      chatMode: 'text',
      gameMode: 'classic',
      onSelect: () => {
        setMessageCount(0);
        setMessages([]);
        setIsGameActive(true);
      }
    },
    {
      id: 'endless',
      title: 'Endless',
      description: 'Chat until you drop',
      icon: <Infinity className="w-6 h-6 text-purple-300" />,
      chatMode: 'text',
      gameMode: 'endless',
      onSelect: () => {
        setMessageCount(0);
        setMessages([]);
        setIsGameActive(true);
      }
    },
    {
      id: 'voice',
      title: 'Voice Chat',
      description: 'Speak your charm',
      icon: <Zap className="w-6 h-6 text-green-300" />,
      chatMode: 'voice',
      gameMode: 'classic',
      onSelect: () => {
        setMessageCount(0);
        setMessages([]);
        setIsGameActive(true);
      }
    }
  ];

  const handleModeSelect = (mode) => {
    try {
      setChatMode(mode.chatMode);
      setGameMode(mode.gameMode);
      if (mode.onSelect) {
        mode.onSelect();
      }
      navigate('/chat');
    } catch (error) {
      console.error('Error in handleModeSelect:', error);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="h-screen bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500 p-6 flex flex-col items-center justify-center"
    >
      <h2 className="text-3xl font-bold text-white mb-8">Choose Your Mode</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-2xl w-full">
        {modes.map((mode) => (
          <motion.button
            key={mode.id}
            onClick={() => handleModeSelect(mode)}
            className="bg-white/10 backdrop-blur-lg rounded-xl p-6 text-white font-semibold hover:bg-white/20 transition-all flex flex-col items-center"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <div className="mb-3">{mode.icon}</div>
            <h3 className="text-xl mb-2">{mode.title}</h3>
            <p className="text-white/70 text-sm">{mode.description}</p>
          </motion.button>
        ))}
      </div>
    </motion.div>
  );
};

export default ModeSelectionScreen;