// src/services/ai.js
export class AIService {
  constructor() {
      // Get API key from environment
      this.OPENROUTER_API_KEY = import.meta.env.VITE_OPENROUTER_API_KEY;
      this.BASE_URL = 'https://openrouter.ai/api/v1';

      if (!this.OPENROUTER_API_KEY) {
          throw new Error('OpenRouter API key is not set in environment variables');
      }

      // Initialize headers once in constructor
      this.headers = {
          'Authorization': `Bearer ${this.OPENROUTER_API_KEY}`,
          'HTTP-Referer': typeof window !== 'undefined' ? window.location.origin : '',
          'X-Title': 'Rizz App',
          'Content-Type': 'application/json',
      };
  }

  async streamChat(messages, onToken) {
      try {
          const response = await fetch(`${this.BASE_URL}/chat/completions`, {
              method: 'POST',
              headers: this.headers,
              body: JSON.stringify({
                  model: 'anthropic/claude-3.5-sonnet:beta',
                  messages,
                  stream: true,
                  temperature: 0.7,
                  max_tokens: 1000,
              }),
          });

          if (!response.ok) {
              const errorText = await response.text();
              throw new Error(`OpenRouter API error: ${response.status} - ${errorText}`);
          }

          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          let buffer = '';

          while (true) {
              const { value, done } = await reader.read();
              
              if (done) break;
              
              buffer += decoder.decode(value, { stream: true });
              const lines = buffer.split('\n');
              
              // Keep last incomplete line in buffer
              buffer = lines.pop() || '';

              for (const line of lines) {
                  if (!line.trim()) continue;
                  
                  if (line.includes('[DONE]')) {
                      return;
                  }

                  if (line.startsWith('data: ')) {
                      try {
                          const data = JSON.parse(line.slice(5));
                          const content = data.choices?.[0]?.delta?.content;
                          if (content) onToken(content);
                      } catch (e) {
                          console.warn('Error parsing stream data:', e, line);
                      }
                  }
              }
          }
      } catch (error) {
          console.error('Stream Error:', error);
          throw new Error(`Streaming failed: ${error.message}`);
      }
  }

  async chatCompletion(messages, model = 'anthropic/claude-3.5-sonnet:beta') {
      try {
          const response = await fetch(`${this.BASE_URL}/chat/completions`, {
              method: 'POST',
              headers: this.headers,
              body: JSON.stringify({
                  model,
                  messages,
                  temperature: 0.7,
                  max_tokens: 1000
              })
          });

          if (!response.ok) {
              const errorText = await response.text();
              throw new Error(`OpenRouter API error: ${response.status} - ${errorText}`);
          }

          return await response.json();
      } catch (error) {
          console.error('Chat completion error:', error);
          throw new Error(`Chat completion failed: ${error.message}`);
      }
  }

  async healthCheck() {
      try {
          const response = await fetch(`${this.BASE_URL}/models`, {
              headers: this.headers
          });

          if (!response.ok) {
              throw new Error(`Health check failed with status: ${response.status}`);
          }

          return true;
      } catch (error) {
          console.error('Health check error:', error);
          return false;
      }
  }
}

// Export a singleton instance
export const aiService = new AIService();
