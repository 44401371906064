// src/components/RequireCharacter.jsx
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../contexts/AppContext';
import LoadingScreen from './chat/LoadingScreen';

const RequireCharacter = ({ component: Component }) => {
  const { selectedCharacter } = useApp();
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedCharacter) {
      console.log('No character selected, redirecting to character selection');
      navigate('/character');
    }
  }, [selectedCharacter, navigate]);

  if (!selectedCharacter) {
    return <LoadingScreen status="redirecting" message="Preparing your experience..." />;
  }

  return <Component />;
};

export default RequireCharacter;