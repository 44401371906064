import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Share2, ChevronDown } from 'lucide-react';
import { useApp } from '../contexts/AppContext';
import { motion, AnimatePresence } from 'framer-motion';
import ShareModal from './ShareModal';

const AnalysisScreen = () => {
  const navigate = useNavigate();
  const { rizzScore, selectedCharacter } = useApp();
  const [expandedSection, setExpandedSection] = useState(null);
  const [showShareModal, setShowShareModal] = useState(false);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: { staggerChildren: 0.1 }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  // Interactive strength meter component
  const StrengthMeter = ({ score }) => (
    <div className="relative w-full h-2 bg-white/10 rounded-full overflow-hidden">
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: `${score * 10}%` }}
        transition={{ duration: 1, ease: "easeOut" }}
        className="absolute h-full bg-gradient-to-r from-pink-500 to-indigo-500"
      />
    </div>
  );

  const sections = [
    {
      id: 'strengths',
      title: "Strengths 💪",
      items: [
        { text: "Natural conversation flow and timing", score: 9.2 },
        { text: "Good use of personal examples", score: 8.8 },
        { text: "Strong active listening skills", score: 8.5 },
        { text: "Appropriate emotional engagement", score: 9.0 },
        { text: "Confident without being overwhelming", score: 8.7 }
      ]
    },
    {
      id: 'improve',
      title: "Areas to Improve 🎯",
      items: [
        { text: "Follow-up question frequency", score: 7.5 },
        { text: "Conversation topic transitions", score: 7.8 },
        { text: "Personal story sharing", score: 7.2 },
        { text: "Response specificity", score: 7.9 },
        { text: "Humor and playfulness", score: 7.4 }
      ]
    },
    {
      id: 'protips',
      title: "Pro Tips 💡",
      items: [
        { text: "Energy level matching", score: 8.4 },
        { text: "Name usage in conversation", score: 7.8 },
        { text: "Relatable experience sharing", score: 8.2 },
        { text: "Open-ended question usage", score: 7.9 },
        { text: "Active listening signals", score: 8.6 }
      ]
    }
  ];

  return (
    <>
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="min-h-screen bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500 p-6"
      >
        {/* Floating Header */}
        <motion.div 
          className="sticky top-0 z-10 -mx-6 -mt-6 px-6 pt-6 pb-4 bg-gradient-to-b from-indigo-600 to-indigo-600/80 backdrop-blur-sm"
          animate={{ y: [0, -5, 0] }}
          transition={{ 
            y: {
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut"
            }
          }}
        >
          <button 
            onClick={() => navigate('/score')}
            className="text-white/90 hover:text-white transition-colors mb-4 flex items-center"
          >
            <ArrowLeft size={24} className="mr-2" />
            <span>Back to Score</span>
          </button>

          <div className="flex items-center justify-between mb-4">
            <h2 className="text-2xl font-bold text-white">Detailed Analysis</h2>
            <div className="flex items-center space-x-4">
              <button 
                onClick={() => setShowShareModal(true)}
                className="bg-white/10 p-2 rounded-full hover:bg-white/20 transition-colors"
              >
                <Share2 size={20} className="text-white" />
              </button>
              <div className="text-right">
                <div className="text-3xl font-bold text-white">{rizzScore}</div>
                <div className="text-sm text-white/60">Rizz Score</div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Analysis Sections */}
        <motion.div 
          variants={container}
          initial="hidden"
          animate="show"
          className="space-y-6 pb-24"
        >
          {sections.map((section) => (
            <motion.div
              key={section.id}
              variants={item}
              className="bg-white/10 backdrop-blur-sm rounded-xl p-4"
            >
              <button
                onClick={() => setExpandedSection(expandedSection === section.id ? null : section.id)}
                className="w-full flex items-center justify-between text-white font-bold mb-3"
              >
                {section.title}
                <motion.div
                  animate={{ rotate: expandedSection === section.id ? 180 : 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <ChevronDown size={20} />
                </motion.div>
              </button>
              
              <AnimatePresence>
                {(expandedSection === section.id || expandedSection === null) && (
                  <motion.div
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: "auto" },
                      collapsed: { opacity: 0, height: 0 }
                    }}
                    transition={{ duration: 0.2 }}
                    className="overflow-hidden"
                  >
                    {section.items.map((item, i) => (
                      <div key={i} className="mb-4">
                        <div className="flex justify-between text-white/80 mb-2">
                          <span>{item.text}</span>
                          <span className="font-bold">{item.score.toFixed(1)}</span>
                        </div>
                        <StrengthMeter score={item.score} />
                      </div>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}

          {/* Personalized Tips */}
          <motion.div
            variants={item}
            className="bg-white/10 backdrop-blur-sm rounded-xl p-4"
          >
            <h3 className="text-white font-bold mb-3">
              Personalized Tips for {selectedCharacter?.type} 🎯
            </h3>
            <p className="text-white/80 mb-4">
              Based on your conversation with {selectedCharacter?.name}, here are some specific tips:
            </p>
            <ul className="space-y-2">
              <li className="text-white/80 flex items-start">
                <span className="mr-2">•</span>
                Show interest in their specific interests ({selectedCharacter?.bio.split(',')[0]})
              </li>
              <li className="text-white/80 flex items-start">
                <span className="mr-2">•</span>
                Match their {selectedCharacter?.mood.toLowerCase()} vibe
              </li>
              <li className="text-white/80 flex items-start">
                <span className="mr-2">•</span>
                Use shared experiences to build rapport
              </li>
            </ul>
          </motion.div>
        </motion.div>

        {/* Action Button */}
        <motion.div className="fixed bottom-6 left-6 right-6">
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => navigate('/')}
            className="w-full bg-gradient-to-r from-pink-500 to-indigo-500 text-white rounded-full px-8 py-4 font-bold text-lg shadow-lg"
          >
            Practice with Someone New
          </motion.button>
        </motion.div>
      </motion.div>

      {/* Share Modal */}
      <AnimatePresence>
        {showShareModal && (
          <ShareModal 
            onClose={() => setShowShareModal(false)} 
            rizzScore={rizzScore}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default AnalysisScreen;