// src/components/chat/VoiceIndicator.jsx
import React from 'react';
import { motion } from 'framer-motion';

const VoiceIndicator = ({ isActive, volume = 0, type = 'user' }) => {
  return (
    <div className="flex items-center justify-center p-4">
      <motion.div
        className={`rounded-full ${
          type === 'user' ? 'bg-white/20' : 'bg-indigo-500/20'
        }`}
        animate={{
          scale: isActive ? 1 + volume * 0.5 : 1,
        }}
        transition={{
          duration: 0.1,
        }}
        style={{
          width: '40px',
          height: '40px',
        }}
      >
        <motion.div
          className={`w-full h-full rounded-full ${
            type === 'user' ? 'bg-white/40' : 'bg-indigo-500/40'
          }`}
          animate={{
            scale: isActive ? 0.8 + volume * 0.2 : 0.8,
          }}
          transition={{
            duration: 0.1,
          }}
        />
      </motion.div>
    </div>
  );
};

export default VoiceIndicator;