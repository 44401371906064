// ScoreScreen.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Share2, ChevronRight } from 'lucide-react';
import { useApp } from '../contexts/AppContext';
import { useProgress } from '../contexts/ProgressContext';
import { motion } from 'framer-motion';

const ScoreScreen = () => {
  const navigate = useNavigate();
  const { rizzScore, selectedCharacter, currentAnalysis, isCalculatingScore, resetGame } = useApp();
  const { stats } = useProgress();
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    // If we don't have necessary data, redirect back to chat
    if (!isCalculatingScore && (!currentAnalysis || !rizzScore || !selectedCharacter)) {
      navigate('/chat');
      return;
    }

    let timeout;
    // Only proceed when calculation is complete and we have data
    if (
      !isCalculatingScore &&
      rizzScore !== null &&
      currentAnalysis &&
      Object.keys(currentAnalysis).length > 0 &&
      selectedCharacter
    ) {
      timeout = setTimeout(() => {
        setShowContent(true);
      }, 500);
    }
    return () => clearTimeout(timeout);
  }, [rizzScore, currentAnalysis, isCalculatingScore, selectedCharacter, navigate]);

  // Show loading state while calculating or if data isn't ready
  if (
    isCalculatingScore ||
    !currentAnalysis ||
    Object.keys(currentAnalysis).length === 0 ||
    rizzScore === null ||
    !showContent ||
    !selectedCharacter
  ) {
    return (
      <div className="fixed inset-0 bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500 flex items-center justify-center p-6">
        <div className="text-center">
          <div className="w-24 h-24 border-4 border-white border-t-transparent rounded-full animate-spin mb-4 mx-auto" />
          <p className="text-white text-xl font-semibold">Preparing Results...</p>
          <p className="text-white/60 mt-2">Almost there!</p>
        </div>
      </div>
    );
  }

  const calculatePercentile = () => {
    const allScores = stats?.recentScores || [];
    if (allScores.length === 0) return 100;
    const belowCurrent = allScores.filter((score) => score < rizzScore).length;
    return Math.round((belowCurrent / allScores.length) * 100);
  };

  const getScoreMessage = (score) => {
    if (score >= 9) return 'Legendary Rizz! 🔥';
    if (score >= 8) return 'Elite Game! ✨';
    if (score >= 7) return 'Pretty Smooth! 💫';
    if (score >= 6) return 'Getting There! 👊';
    return 'Keep Practicing! 💪';
  };

  const renderStrengthBar = (score) => {
    const percentage = (score / 10) * 100;
    return (
      <div className="w-full bg-white/10 rounded-full h-2 overflow-hidden">
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: `${percentage}%` }}
          transition={{ duration: 1, delay: 0.5 }}
          className="h-full bg-gradient-to-r from-pink-500 to-indigo-500 rounded-full"
        />
      </div>
    );
  };

  // Show rating change if available
  const renderRatingChange = () => {
    if (currentAnalysis?.ratingChange !== undefined) {
      const isPositive = currentAnalysis.ratingChange > 0;
      return (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className={`text-sm ${isPositive ? 'text-green-400' : 'text-red-400'}`}
        >
          {isPositive ? '+' : ''}
          {currentAnalysis.ratingChange} Rating
        </motion.div>
      );
    }
    return null;
  };

  return (
    <div className="fixed inset-0 bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500 flex flex-col">
      <div className="ios-scroll-container flex-1 overflow-y-auto">
        <div className="max-w-md mx-auto p-6">
          {/* Score Circle */}
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: 'spring', duration: 0.8 }}
            className="flex justify-center mb-8 pt-4"
          >
            <div className="w-48 h-48 rounded-full bg-white/10 backdrop-blur-sm flex items-center justify-center">
              <div className="w-40 h-40 rounded-full bg-white/20 flex items-center justify-center">
                <div className="text-center">
                  <motion.div className="space-y-1">
                    <motion.h2
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.3 }}
                      className="text-6xl font-bold text-white"
                    >
                      {rizzScore?.toFixed(1)}
                    </motion.h2>
                    <p className="text-white/80">Rizz Score</p>
                    {renderRatingChange()}
                  </motion.div>
                </div>
              </div>
            </div>
          </motion.div>

          {/* Score Message */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
            className="text-center mb-8"
          >
            <h3 className="text-2xl font-bold text-white mb-2">{getScoreMessage(rizzScore)}</h3>
            <p className="text-white/80">
              You're in the top {calculatePercentile()}% of rizz masters
            </p>
          </motion.div>

          {/* Detailed Analysis */}
          {currentAnalysis?.strengths && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.7 }}
              className="bg-white/10 backdrop-blur-sm rounded-xl p-4 mb-6"
            >
              <h4 className="text-white font-bold mb-4">Skill Breakdown:</h4>

              {Object.entries(currentAnalysis.strengths).map(([key, value]) => (
                <div key={key} className="mb-4">
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-white/80 capitalize">
                      {key.replace(/([A-Z])/g, ' $1').trim()}
                    </span>
                    <span className="text-white font-bold">{value.score?.toFixed(1) || 'N/A'}</span>
                  </div>
                  {renderStrengthBar(value.score || 0)}
                  <p className="text-white/60 text-sm mt-1">{value.explanation || 'No data'}</p>
                </div>
              ))}
            </motion.div>
          )}

          {/* Best Moments */}
          {currentAnalysis?.analysis?.bestMoments && currentAnalysis.analysis.bestMoments.length > 0 && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.9 }}
              className="bg-white/10 backdrop-blur-sm rounded-xl p-4 mb-6"
            >
              <h4 className="text-white font-bold mb-2">Best Moments:</h4>
              <ul className="space-y-2">
                {currentAnalysis.analysis.bestMoments.map((moment, index) => (
                  <li key={index} className="text-white/80 flex items-start">
                    <span className="mr-2">✨</span>
                    {moment}
                  </li>
                ))}
              </ul>
            </motion.div>
          )}
        </div>
      </div>

      {/* Action Buttons - Sticky to bottom */}
      <div className="sticky bottom-0 bg-gradient-to-t from-indigo-600 via-indigo-600/95 to-transparent pt-12 pb-6">
        <div className="max-w-md mx-auto px-6 space-y-4">
          <motion.button
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.1 }}
            onClick={() => navigate('/analysis')}
            className="bg-gradient-to-r from-pink-500 to-indigo-500 text-white rounded-full px-8 py-4 font-bold text-lg w-full flex items-center justify-center"
          >
            Get Full Analysis <ChevronRight className="ml-2" size={20} />
          </motion.button>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.3 }}
            className="flex space-x-4"
          >
            <button
              onClick={() => {
                /* Share logic */
              }}
              className="flex-1 bg-white/10 backdrop-blur-sm rounded-full py-3 px-6 text-white flex items-center justify-center"
            >
              <Share2 size={20} className="mr-2" /> Share
            </button>

            <button
              onClick={() => {
                resetGame();
                navigate('/gender');
              }}
              className="flex-1 bg-white/10 backdrop-blur-sm rounded-full py-3 px-6 text-white"
            >
              Try Again
            </button>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default ScoreScreen;
