import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { capacitor } from './native/capacitor';
import { AppProvider } from './contexts/AppContext';
import { AuthProvider } from './contexts/AuthContext';
import { ProgressProvider } from './contexts/ProgressContext';
import RequireCharacter from './components/RequireCharacter';

// Import base components
import LandingScreen from './components/LandingScreen';
import GenderSelect from './components/GenderSelect';
import CharacterSelect from './components/CharacterSelect';
import ModeSelectionScreen from './components/ModeSelectionScreen';
import ScoreScreen from './components/ScoreScreen';
import AnalysisScreen from './components/AnalysisScreen';
import Leaderboard from './components/Leaderboard';
import Register from './components/Register';
import Login from './components/Login';
import VerifyEmail from './components/VerifyEmail';
import VerificationPending from './components/VerificationPending';
import ProfileScreen from './components/ProfileScreen';
import ProtectedRoute from './components/ProtectedRoute';
import ChatScreen from './components/chat/ChatScreen';

// Development logging
if (import.meta.env.DEV) {
  const originalFetch = window.fetch;
  window.fetch = async (...args) => {
    const [url, options] = args;

    console.group(`🌐 Fetch Request: ${options?.method || 'GET'} ${url}`);
    console.log('📤 Request options:', {
      ...options,
      headers: options?.headers
        ? Object.fromEntries(
            Object.entries(options.headers).map(([key, value]) => [
              key,
              key.toLowerCase() === 'authorization' ? '******' : value,
            ])
          )
        : {},
    });

    try {
      const startTime = performance.now();
      const response = await originalFetch(...args);
      const endTime = performance.now();
      const responseClone = response.clone();

      console.log(`⏱️ Response time: ${(endTime - startTime).toFixed(2)}ms`);
      console.log('📥 Response status:', response.status);
      console.log(
        '📥 Response headers:',
        Object.fromEntries(response.headers.entries())
      );

      try {
        const responseData = await responseClone.text();
        if (responseData) {
          try {
            console.log('📥 Response body:', JSON.parse(responseData));
          } catch {
            console.log('📥 Response body (text):', responseData);
          }
        }
      } catch (e) {
        console.log('⚠️ Could not read response body');
      }

      console.groupEnd();
      return response;
    } catch (error) {
      console.error('❌ Fetch error:', {
        message: error.message,
        stack: error.stack,
        type: error.constructor.name,
      });
      console.groupEnd();
      throw error;
    }
  };

  window.addEventListener('unhandledrejection', (event) => {
    console.error('🔥 Unhandled promise rejection:', {
      reason: event.reason,
      stack: event.reason?.stack,
      message: event.reason?.message,
    });
  });

  window.addEventListener('error', (event) => {
    console.error('💥 Global error:', {
      message: event.message,
      filename: event.filename,
      lineno: event.lineno,
      colno: event.colno,
      error: event.error,
    });
  });

  console.log('🔧 Development logging initialized');
}

function App() {
  useEffect(() => {
    let cleanup = () => {};

    const initApp = async () => {
      const native = await capacitor.initialize();
      cleanup = native.cleanup;
    };

    initApp().catch(err => {
      console.warn('App initialization error:', err);
    });

    return () => cleanup();
  }, []);

  return (
    <Router>
      <AuthProvider>
        <ProgressProvider>
          <AppProvider>
            <div className="ios-scroll-container max-w-md mx-auto min-h-screen bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500">
              <div className="safe-top safe-bottom safe-left safe-right">
                <Routes>
                  {/* Public Routes */}
                  <Route path="/" element={<LandingScreen />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/verify-email" element={<VerifyEmail />} />
                  <Route path="/verification-pending" element={<VerificationPending />} />

                  {/* Protected Routes */}
                  <Route path="/profile" element={<ProtectedRoute component={ProfileScreen} />} />
                  <Route path="/gender" element={<ProtectedRoute component={GenderSelect} />} />
                  <Route path="/character" element={<ProtectedRoute component={CharacterSelect} />} />
                  <Route path="/mode" element={<ProtectedRoute component={ModeSelectionScreen} />} />
                  
                  {/* Routes that require character selection */}
                  <Route 
                    path="/chat" 
                    element={
                      <ProtectedRoute 
                        component={() => <RequireCharacter component={ChatScreen} />} 
                      />
                    } 
                  />
                  <Route 
                    path="/score" 
                    element={
                      <ProtectedRoute 
                        component={() => <RequireCharacter component={ScoreScreen} />} 
                      />
                    } 
                  />
                  <Route 
                    path="/analysis" 
                    element={
                      <ProtectedRoute 
                        component={() => <RequireCharacter component={AnalysisScreen} />} 
                      />
                    } 
                  />
                  <Route path="/leaderboard" element={<ProtectedRoute component={Leaderboard} />} />
                </Routes>
              </div>
            </div>
          </AppProvider>
        </ProgressProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
