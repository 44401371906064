// src/services/ChatService.js
export class ChatService {
  constructor(aiService) {
    this.aiService = aiService;
    this.lastAnalysisTime = Date.now();
    this.analysisThrottle = 2000;
    this.analysisCache = new Map();
    this.lastResponse = null;
    this.retryAttempts = 2;
    this.retryDelay = 1000;
    this.MAX_CACHE_SIZE = 20;
  }

  async calculateRizzScore(conversationHistory, force = false) {
    try {
      const now = Date.now();
      const cacheKey = JSON.stringify(conversationHistory);

      // Check cache
      if (!force && this.analysisCache.has(cacheKey)) {
        console.log('Returning cached analysis');
        return this.analysisCache.get(cacheKey);
      }

      // Check throttle
      if (!force && now - this.lastAnalysisTime < this.analysisThrottle) {
        console.log('Throttled, waiting...');
        await new Promise(resolve => setTimeout(resolve, this.analysisThrottle));
      }

      this.lastAnalysisTime = now;

      // Try multiple times if needed
      let lastError = null;
      for (let attempt = 0; attempt < this.retryAttempts; attempt++) {
        try {
          const analysis = await this._performAnalysis(conversationHistory);
          this.lastResponse = analysis;
          
          // Cache successful result
          this._updateCache(cacheKey, analysis);
          
          return analysis;
        } catch (error) {
          console.error(`Analysis attempt ${attempt + 1} failed:`, error);
          lastError = error;
          
          if (attempt < this.retryAttempts - 1) {
            await new Promise(resolve => setTimeout(resolve, this.retryDelay));
          }
        }
      }

      // If all attempts failed, return last successful response or fallback
      return this.lastResponse || this.getFallbackAnalysis(conversationHistory);

    } catch (error) {
      console.error('Fatal error in calculateRizzScore:', error);
      return this.getFallbackAnalysis(conversationHistory);
    }
  }

  async _performAnalysis(conversationHistory) {
    const prompt = `Analyze this conversation, this is game where the user flirts with an AI.
      
    Your purpose is to calculate the users performance, this is important as the users will be ranked on a worldwide leaderboard.
    
    SCORING GUIDELINES:
    
    Conversation Flow (how naturally the conversation progresses):
    0: Complete nonsense or no responses
    1: Random, disconnected responses
    2: Very poor flow with constant topic jumping
    3: Poor flow with frequent awkward transitions
    4: Below average flow with some abrupt changes
    5: Average flow with occasional awkward moments
    6: Above average flow with decent transitions
    7: Good flow with smooth transitions
    8: Very good flow with natural progression
    9: Excellent flow that feels completely natural
    10: Perfect flow with masterful conversation control
    
    Emotional Connection (ability to create and maintain rapport):
    0: Completely cold or hostile
    1: Actively dismissive or negative
    2: Shows no interest in connecting
    3: Minimal attempt at emotional engagement
    4: Basic but superficial connection
    5: Average emotional engagement
    6: Shows genuine interest and empathy
    7: Creates good emotional rapport
    8: Strong emotional connection
    9: Deep emotional understanding
    10: Exceptional emotional intelligence and connection
    
    Flirting Skills (appropriate romantic/playful interaction):
    0: Inappropriate or offensive
    1: Extremely awkward or uncomfortable
    2: Very poor attempts at flirting
    3: Unsuccessful flirting attempts
    4: Basic but ineffective flirting
    5: Average flirting ability
    6: Above average charm
    7: Good flirting with style
    8: Very effective and charming
    9: Exceptional flirting skills
    10: Master of romantic interaction
    
    Confidence (self-assurance and communication style):
    0: Complete lack of confidence
    1: Extremely insecure
    2: Very timid and uncertain
    3: Noticeably lacking confidence
    4: Below average confidence
    5: Average confidence level
    6: Above average confidence
    7: Good confidence without arrogance
    8: Very confident and assured
    9: Excellent confidence and poise
    10: Perfect balance of confidence
    
    Humor (ability to use wit and playfulness):
    0: Offensive or inappropriate humor
    1: No attempt at humor
    2: Failed attempts at humor
    3: Poor humor execution
    4: Basic but forced humor
    5: Average humor usage
    6: Above average wit
    7: Good humor and timing
    8: Very funny and appropriate
    9: Excellent wit and charm
    10: Perfect humor execution

    Return a JSON object strictly following this exact format:
    {
      "scores": {
        "conversationFlow": {"score": 8, "explanation": "Brief reason", "examples": ["Example 1", "Example 2"]},
        "emotionalConnection": {"score": 8, "explanation": "Brief reason", "examples": ["Example 1", "Example 2"]},
        "flirtingSkills": {"score": 8, "explanation": "Brief reason", "examples": ["Example 1", "Example 2"]},
        "confidence": {"score": 8, "explanation": "Brief reason", "examples": ["Example 1", "Example 2"]},
        "humor": {"score": 8, "explanation": "Brief reason", "examples": ["Example 1", "Example 2"]}
      },
      "analysis": {
        "bestMoments": ["Moment 1", "Moment 2"],
        "improvementAreas": ["Area 1", "Area 2"],
        "overallCharm": 8
      }
    }

    RULES:
    1. Score between 1-10 only
    2. Simple explanations, no special characters
    3. Use plain text examples
    4. Pure JSON output
    5. No arrows or special symbols
    6. Basic punctuation only
    7. Format as User said/AI said
    8. Follow scoring guidelines exactly
    
    ONLY RETURN THE JSON NOTHING ELSE OR IT WONT WORK`;

    const response = await this.aiService.chatCompletion(
      [
        { role: 'system', content: prompt },
        {
          role: 'user',
          content: `Analyze this conversation:\n${conversationHistory.map(msg => 
            `${msg.sender.toUpperCase()}: ${msg.text}`
          ).join('\n')}`,
        },
      ],
      'anthropic/claude-3.5-sonnet:beta'
    );

    const analysisText = this.cleanAnalysisText(response.choices[0].message.content);
    
    if (process.env.NODE_ENV === 'development') {
      console.log('Cleaned analysis:', analysisText);
    }

    return this.parseAndValidateAnalysis(analysisText);
  }

  _updateCache(key, value) {
    this.analysisCache.set(key, value);
    if (this.analysisCache.size > this.MAX_CACHE_SIZE) {
      const oldestKey = this.analysisCache.keys().next().value;
      this.analysisCache.delete(oldestKey);
    }
  }

  cleanAnalysisText(text) {
    return text
      .trim()
      .replace(/```json/g, '')
      .replace(/```/g, '')
      .replace(/->|=>/g, ':')
      .replace(/["']([^"']*->.*?)["']/g, '"$1"')
      .replace(/[\u2018]/g, "'")
      .replace(/[\u201C\u201D]/g, '"')
      .trim();
  }

  parseAndValidateAnalysis(analysisText) {
    try {
      const analysis = JSON.parse(analysisText);
      
      if (!analysis.scores || !analysis.analysis) {
        throw new Error('Invalid analysis structure');
      }

      // Validate and clean up scores
      Object.keys(analysis.scores).forEach(key => {
        const score = analysis.scores[key];
        score.score = Math.min(10, Math.max(1, Number(score.score) || 7));
        score.explanation = score.explanation || 'No explanation provided';
        score.examples = (score.examples || []).map(ex => 
          ex.replace(/->|=>/g, ':').replace(/["']/g, '').trim()
        ).filter(ex => ex);
        
        if (score.examples.length === 0) {
          score.examples = ['No specific examples provided'];
        }
      });

      // Ensure required fields exist
      analysis.analysis.bestMoments = analysis.analysis.bestMoments || [];
      analysis.analysis.improvementAreas = analysis.analysis.improvementAreas || [];
      analysis.analysis.overallCharm = Math.min(10, Math.max(1, Number(analysis.analysis.overallCharm) || 7));

      // Calculate overall score
      const scores = Object.values(analysis.scores).map(item => item.score);
      const averageScore = scores.reduce((a, b) => a + b, 0) / scores.length;
      const overallScore = (averageScore + analysis.analysis.overallCharm) / 2;

      return {
        score: Number(overallScore.toFixed(1)),
        strengths: analysis.scores,
        analysis: analysis.analysis,
      };
    } catch (error) {
      console.error('Analysis parsing error:', error);
      throw error;
    }
  }

  getFallbackAnalysis(conversationHistory = []) {
    // Basic scoring based on conversation length
    const baseScore = Math.max(1, Math.min(7.5, conversationHistory.length / 2));
    
    return {
      score: baseScore,
      strengths: {
        conversationFlow: {
          score: baseScore,
          explanation: "Conversation analysis unavailable",
          examples: ["System could not analyze specific examples"]
        },
        emotionalConnection: {
          score: baseScore,
          explanation: "Connection analysis unavailable",
          examples: ["System could not analyze specific examples"]
        },
        flirtingSkills: {
          score: baseScore,
          explanation: "Flirting analysis unavailable",
          examples: ["System could not analyze specific examples"]
        },
        confidence: {
          score: baseScore,
          explanation: "Confidence analysis unavailable",
          examples: ["System could not analyze specific examples"]
        },
        humor: {
          score: baseScore,
          explanation: "Humor analysis unavailable",
          examples: ["System could not analyze specific examples"]
        }
      },
      analysis: {
        bestMoments: ["Analysis unavailable"],
        improvementAreas: ["Try again for detailed analysis"],
        overallCharm: baseScore
      }
    };
  }
}