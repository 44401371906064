// ProfileScreen.jsx
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { LogOut, Trophy, Heart, ArrowLeft, Star, Clock } from 'lucide-react';

const ProfileScreen = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [userStats, setUserStats] = useState(null);
  const [recentGames, setRecentGames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchUserStats();
  }, []);

  const fetchUserStats = async () => {
    try {
      const response = await fetch('/api/user/stats', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        setUserStats(data.stats);
        setRecentGames(data.recentGames);
      }
    } catch (error) {
      console.error('Failed to fetch user stats:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-white border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500 p-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <ArrowLeft
          className="text-white/90 cursor-pointer"
          size={24}
          onClick={() => navigate('/')}
        />
        <button
          onClick={handleLogout}
          className="flex items-center space-x-2 text-white/90 hover:text-white"
        >
          <LogOut size={20} />
          <span>Logout</span>
        </button>
      </div>

      {/* Profile Header */}
      <div className="flex items-center space-x-4 mb-8">
        <div className="w-20 h-20 rounded-full bg-white/20 flex items-center justify-center">
          <span className="text-3xl text-white">
            {user?.name?.charAt(0).toUpperCase() || user?.email?.charAt(0).toUpperCase() || '?'}
          </span>
        </div>
        <div>
          <h2 className="text-2xl font-bold text-white">{user?.name || 'User'}</h2>
          <p className="text-white/60">{user?.email}</p>
          <div className="flex items-center space-x-2 mt-1">
            <Trophy className="text-yellow-300" size={16} />
            <span className="text-white/80">Rating: {user?.rizzRating || 1000}</span>
          </div>
        </div>
      </div>

      {/* Stats Cards */}
      <div className="grid grid-cols-2 gap-4 mb-8">
        <div className="bg-white/10 backdrop-blur-sm rounded-xl p-4">
          <div className="flex items-center space-x-2 mb-2">
            <Star className="text-yellow-300" size={20} />
            <span className="text-white font-semibold">Best Score</span>
          </div>
          <p className="text-3xl font-bold text-white">{user?.bestScore?.toFixed(1) || '0.0'}</p>
        </div>
        <div className="bg-white/10 backdrop-blur-sm rounded-xl p-4">
          <div className="flex items-center space-x-2 mb-2">
            <Heart className="text-pink-300" size={20} />
            <span className="text-white font-semibold">Games</span>
          </div>
          <p className="text-3xl font-bold text-white">{user?.gamesPlayed || 0}</p>
        </div>
      </div>

      {/* Mode Stats */}
      <div className="bg-white/10 backdrop-blur-sm rounded-xl p-4 mb-8">
        <h3 className="text-white font-semibold mb-4">Game Modes</h3>
        <div className="space-y-4">
          {Object.entries(userStats?.modeStats || {}).map(([mode, stats]) => (
            <div key={mode} className="flex justify-between items-center">
              <div>
                <p className="text-white capitalize">{mode}</p>
                <p className="text-white/60 text-sm">Best: {stats.bestScore.toFixed(1)}</p>
              </div>
              <div className="text-right">
                <p className="text-white">{stats.games} games</p>
                <p className="text-white/60 text-sm">Avg: {stats.avgScore.toFixed(1)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Recent Games */}
      <div className="bg-white/10 backdrop-blur-sm rounded-xl p-4">
        <h3 className="text-white font-semibold mb-4">Recent Games</h3>
        {recentGames.length > 0 ? (
          <div className="space-y-4">
            {recentGames.map((game, index) => (
              <div key={index} className="flex justify-between items-center border-b border-white/10 pb-4">
                <div>
                  <p className="text-white">{game.characterId}</p>
                  <p className="text-white/60 text-sm">{formatDate(game.createdAt)}</p>
                </div>
                <div className="text-right">
                  <p className="text-white font-bold">{game.score.toFixed(1)}</p>
                  <p className={`text-sm ${game.ratingChange >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                    {game.ratingChange >= 0 ? '+' : ''}{game.ratingChange} Rating
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-white/60 text-center py-4">No recent games</p>
        )}
      </div>
    </div>
  );
};

export default ProfileScreen;