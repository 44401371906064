// ChatScreen.jsx
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../../contexts/AppContext';
import ChatHeader from './ChatHeader';
import ChatMessages from './ChatMessages';
import ChatInput from './ChatInput';
import ChatError from './ChatError';
import LoadingScreen from './LoadingScreen';
import VoiceChat from './VoiceChat';
import GameHUD from '../GameHUD';

import { useGameLogic } from './hooks/useGameLogic';
import { useVoiceChat } from './hooks/useVoiceChat';
import { useMessageHandling } from './hooks/useMessageHandling';
import { useInitialization } from './hooks/useInitializations';

const ChatScreen = () => {
  const navigate = useNavigate();
  const { 
    selectedCharacter, 
    chatMode, 
    isGameActive, 
    gameMode,
    isCalculatingScore,
  } = useApp();

  // Only initialize voice chat when in voice mode
  const voiceChatHook = chatMode === 'voice' ? useVoiceChat(chatMode) : {
    isVoiceMode: false,
    isAssistantSpeaking: false,
    volumeLevel: 0,
    error: null,
    isInitialized: false,
    vapiRef: { current: null },
    reinitialize: () => {}
  };

  const { 
    isVoiceMode, 
    isAssistantSpeaking, 
    volumeLevel, 
    error: voiceError,
    isInitialized,
    vapiRef,
    reinitialize
  } = voiceChatHook;

  const debugLog = (message, data = {}) => {
    console.group(`📱 ChatScreen: ${message}`);
    console.log('State:', {
      chatMode,
      hasSelectedCharacter: !!selectedCharacter,
      serviceStatus,
      isCalculatingScore,
      isInitialized,
      hasError: !!initError || !!voiceError,
      isGameActive,
      gameMode
    });
    if (Object.keys(data).length > 0) {
      console.log('Data:', data);
    }
    console.groupEnd();
  };

  useEffect(() => {
    if (!selectedCharacter) {
      debugLog('No character selected, redirecting to character selection');
      navigate('/character');
    }
  }, [selectedCharacter, navigate]);

  const {
    serviceStatus,
    error: initError,
    setError: setInitError,
    messages,
    isTyping: initTyping,
    messagesEndRef,
  } = useInitialization();

  const {
    lifeBar,
    analyzeAndUpdateLifeBar,
    updateLastResponseTime,
  } = useGameLogic();

  const {
    inputText,
    setInputText,
    handleKeyPress,
    handleSendMessage: baseHandleSendMessage,
    isTyping: messageTyping,
    messageCount,
    error: messageError,
  } = useMessageHandling(isGameActive);

  // Wrap handleSendMessage to update last response time
  const handleSendMessage = async () => {
    await baseHandleSendMessage();
    updateLastResponseTime();
  };

  // Combine typing states
  const isTyping = initTyping || messageTyping;

  // Effect to handle loading screen visibility
  useEffect(() => {
    debugLog('Loading screen state check', {
      isCalculatingScore,
      serviceStatus,
      isGameActive
    });
  }, [isCalculatingScore, serviceStatus, isGameActive]);

  if (!selectedCharacter) {
    debugLog('No character selected, showing redirect screen');
    return <LoadingScreen status="redirecting" message="Preparing chat..." />;
  }

  if (isCalculatingScore) {
    debugLog('Showing calculation screen');
    return (
      <LoadingScreen 
        status="calculating" 
        message="Calculating your Rizz score..." 
        hasRetry={false}
      />
    );
  }

  if (serviceStatus !== 'ready') {
    debugLog('Service not ready');
    return <LoadingScreen status={serviceStatus} />;
  }

  if (chatMode === 'voice' && !isInitialized && gameMode !== 'lifebar') {
    debugLog('Voice chat initializing', { voiceError });
    return (
      <LoadingScreen 
        status="initializing" 
        message={voiceError || "Initializing voice chat..."}
        hasRetry={true}
        onRetry={() => {
          debugLog('Retry initialization requested');
          reinitialize();
        }}
      />
    );
  }

  debugLog('Rendering chat screen');
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="h-screen bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500 flex flex-col relative"
    >
      <GameHUD currentLifeBar={lifeBar} />
      <ChatHeader selectedCharacter={selectedCharacter} />

      <ChatMessages
        messages={messages}
        isTyping={isTyping}
        chatMode={chatMode}
        messagesEndRef={messagesEndRef}
      />

      {chatMode === 'text' ? (
        <ChatInput
          inputText={inputText}
          setInputText={setInputText}
          handleKeyPress={handleKeyPress}
          messageCount={messageCount}
          isTyping={isTyping}
          handleSendMessage={handleSendMessage}
          disabled={!isGameActive || isCalculatingScore}
        />
      ) : (
        <VoiceChat
          vapiRef={vapiRef}
          isVoiceMode={isVoiceMode}
          isAssistantSpeaking={isAssistantSpeaking}
          volumeLevel={volumeLevel}
          selectedCharacter={selectedCharacter}
          disabled={!isGameActive || isCalculatingScore}
          error={voiceError}
          isInitialized={isInitialized}
          reinitialize={reinitialize}
        />
      )}

      <ChatError
        error={initError || voiceError || messageError}
        setError={setInitError}
      />
    </motion.div>
  );
};

export default ChatScreen;
