// packages/frontend/src/components/chat/VoiceChat.jsx
import React from 'react';
import VoiceIndicator from './VoiceIndicator';

const VoiceChat = ({ 
  vapiRef,
  isVoiceMode,
  isAssistantSpeaking,
  volumeLevel,
  selectedCharacter,
  disabled,
  error,
  isInitialized,
  reinitialize 
}) => {
  if (!isInitialized) {
    return (
      <div className="p-4 text-center">
        <div>Initializing voice chat...</div>
        {error && (
          <button 
            onClick={reinitialize}
            className="mt-2 px-4 py-2 bg-white/10 rounded-full"
          >
            Retry
          </button>
        )}
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-center">
        <div>Error: {error}</div>
        <button 
          onClick={reinitialize}
          className="mt-2 px-4 py-2 bg-white/10 rounded-full"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="p-4 bg-black/20 backdrop-blur-sm border-t border-white/10">
      <div className="flex justify-center items-center space-x-8">
        <VoiceIndicator 
          isActive={isVoiceMode && !isAssistantSpeaking && !disabled} 
          type="user" 
        />
        <VoiceIndicator 
          isActive={isAssistantSpeaking && !disabled} 
          type="assistant" 
        />
      </div>
    </div>
  );
};

export default VoiceChat;