// src/components/chat/ChatMessages.jsx
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const ChatMessages = ({ messages, isTyping, chatMode, messagesEndRef }) => {
  return (
    <div className="flex-1 p-4 overflow-y-auto flex flex-col">
      <AnimatePresence>
        {messages.map((msg) => (
          <motion.div
            key={msg.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className={`${msg.sender === 'ai' ? 'self-start' : 'self-end'} flex flex-col mb-4`}
          >
            <div
              className={`${
                msg.sender === 'ai' ? 'bg-white/10' : 'bg-white/20'
              } backdrop-blur-sm rounded-xl p-4 max-w-[80%]`}
            >
              <p className="text-white">{msg.text}</p>
            </div>
          </motion.div>
        ))}
      </AnimatePresence>
      {isTyping && chatMode === 'text' && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="bg-white/10 backdrop-blur-sm rounded-xl p-4 max-w-[80%] self-start"
        >
          <div className="flex space-x-2">
            <div className="w-2 h-2 bg-white/60 rounded-full animate-bounce" />
            <div className="w-2 h-2 bg-white/60 rounded-full animate-bounce delay-100" />
            <div className="w-2 h-2 bg-white/60 rounded-full animate-bounce delay-200" />
          </div>
        </motion.div>
      )}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default ChatMessages;