// src/components/chat/ChatHeader.jsx
import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const ChatHeader = ({ selectedCharacter }) => {
  const navigate = useNavigate();

  return (
    <div className="p-4 border-b border-white/10 backdrop-blur-sm">
      <div className="flex items-center">
        <button
          onClick={() => navigate('/character')}
          className="text-white/90 mr-4"
        >
          <ArrowLeft size={24} />
        </button>
        <div
          className={`w-10 h-10 rounded-full bg-gradient-to-r ${selectedCharacter?.gradient} mr-3`}
        >
          <img
            src={`https://api.dicebear.com/6.x/avataaars/svg?seed=${selectedCharacter?.name}`}
            alt={selectedCharacter?.name}
            className="w-full h-full rounded-full"
          />
        </div>
        <div>
          <h3 className="text-white font-semibold">{selectedCharacter?.name}</h3>
          <p className="text-white/60 text-sm">{selectedCharacter?.type}</p>
        </div>
      </div>
    </div>
  );
};

export default ChatHeader;