// src/services/ResponseAnalyzer.js
export class ResponseAnalyzer {
    constructor(aiService) {
      this.aiService = aiService;
    }
  
    async analyzeResponse(aiMessage, userResponse) {
      const prompt = `Analyze this dating/flirting conversation exchange.
      You must output ONLY the number 1 or 2, nothing else.
      
      AI's previous message: "${aiMessage}"
      Human's response: "${userResponse}"
  
      Output 1 if the human's response:
      - Shows engagement and interest
      - Is emotionally connecting
      - Uses humor appropriately
      - Is charming or flirty
      - Maintains good conversation flow
  
      Output 2 if the response:
      - Is dismissive or rude
      - Shows lack of interest
      - Is inappropriate
      - Is extremely brief or unengaged
      - Kills the conversation flow
  
      Output only 1 or 2, no other text:`;
  
      try {
        const response = await this.aiService.chatCompletion(
          [{
            role: 'system',
            content: prompt
          }],
          'nvidia/llama-3.1-nemotron-70b-instruct'
        );
  
        const rating = parseInt(response.choices[0].message.content.trim());
        return rating === 1 ? 'good' : 'bad';
      } catch (error) {
        console.error('Response analysis error:', error);
        return 'neutral';
      }
    }
  }