import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App.jsx';


//testing github actions

// Separate file for native functionality
const isNativeApp = window.location.protocol === 'capacitor:';

const initNative = async () => {
  if (!isNativeApp) return;

  try {
    // Only import Capacitor in native environment
    const { Capacitor } = await import('./native/capacitor.js');
    if (Capacitor.isNativePlatform()) {
      await Capacitor.setupNative();
    }
  } catch (err) {
    console.log('Web environment - Capacitor not loaded');
  }
};

const startApp = () => {
  createRoot(document.getElementById('root')).render(
    <StrictMode>
      <App />
    </StrictMode>
  );

  // Initialize native features after render
  initNative().catch(console.error);
};

startApp();