// src/components/chat/ChatError.jsx
import React from 'react';

const ChatError = ({ error, setError }) => {
  if (!error) return null;

  return (
    <div className="fixed top-4 right-4 bg-red-500/90 text-white px-4 py-2 rounded-lg z-50">
      <p>{error}</p>
      <button onClick={() => setError(null)} className="text-sm underline mt-1">
        Dismiss
      </button>
    </div>
  );
};

export default ChatError;