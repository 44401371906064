// src/components/chat/hooks/useInitialization.js
import { useState, useEffect, useRef } from 'react';
import { useApp } from '../../../contexts/AppContext';
import { AIService } from '../../../services/ai';
import { useAuth } from '../../../contexts/AuthContext'; // Add this import

export const useInitialization = () => {
  const { selectedCharacter, messages, setMessages, chatMode } = useApp();
  const { user } = useAuth(); // Add this line
  const [isTyping, setIsTyping] = useState(false);
  const [error, setError] = useState(null);
  const [serviceStatus, setServiceStatus] = useState('checking');
  
  const messagesEndRef = useRef(null);
  const aiService = useRef(new AIService());
  const messageIdRef = useRef(1);

  // Initialize chat with first message
  useEffect(() => {
    const initializeChat = async () => {
      if (messages.length === 0 && selectedCharacter && serviceStatus === 'ready' && chatMode === 'text') {
        setIsTyping(true);
        try {
          let initialMessage = '';
          const userName = user?.name || 'there';
          
          await aiService.current.streamChat(
            [
              {
                role: 'system',
                content: `${selectedCharacter.systemPrompt}\n\nYou are talking to ${userName}. Remember their name and use it naturally in conversation when appropriate.`,
              },
              {
                role: 'user',
                content: `Hi, I'm ${userName}. Start a conversation with me as if you just saw me at a coffee shop. Make your response natural and casual.`,
              },
            ],
            (token) => {
              initialMessage += token;
              setMessages([
                {
                  id: messageIdRef.current,
                  text: initialMessage,
                  sender: 'ai',
                  character: selectedCharacter.name,
                },
              ]);
            }
          );
          messageIdRef.current += 1;
        } catch (error) {
          console.error('Error initializing chat:', error);
          setError('Failed to start chat. Please refresh the page.');
          setMessages([
            {
              id: messageIdRef.current,
              text: `Hey ${user?.name || 'there'}! I saw you at the coffee shop earlier... 😊`,
              sender: 'ai',
              character: selectedCharacter.name,
            },
          ]);
          messageIdRef.current += 1;
        } finally {
          setIsTyping(false);
        }
      }
    };

    initializeChat();
  }, [selectedCharacter, messages.length, serviceStatus, chatMode, setMessages, user]);

  // Initialize services and check health
  useEffect(() => {
    const initializeServices = async () => {
      try {
        const isHealthy = await aiService.current.healthCheck();
        setServiceStatus(isHealthy ? 'ready' : 'error');
      } catch (error) {
        console.error('Health check failed:', error);
        setServiceStatus('error');
        setError('Unable to connect to AI service. Please try again later.');
      }
    };

    initializeServices();
  }, []);

  // Auto-scroll effect
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return {
    serviceStatus,
    error,
    setError,
    messages,
    isTyping,
    setIsTyping,
    messagesEndRef,
    aiService,
    messageIdRef
  };
};