import React, { useState } from 'react';
import { Copy, Twitter, Instagram } from 'lucide-react';
import { motion } from 'framer-motion';

const ShareModal = ({ onClose, rizzScore }) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const handleShare = async (platform) => {
    const shareText = `Just scored ${rizzScore} on my Rizz test! 🔥\nCheck out my conversation skills at rizzapp.com`;
    
    switch (platform) {
      case 'clipboard':
        try {
          await navigator.clipboard.writeText(shareText);
          setCopiedToClipboard(true);
          setTimeout(() => setCopiedToClipboard(false), 2000);
        } catch (err) {
          console.error('Failed to copy:', err);
        }
        break;
      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}`);
        break;
      case 'instagram':
        // In reality, you'd probably want to generate a shareable image here
        alert('Instagram sharing would open Instagram app with a story template');
        break;
    }
    setTimeout(() => onClose(), 500);
  };

  const shareOptions = [
    {
      id: 'clipboard',
      icon: Copy,
      label: 'Copy Link',
      action: () => handleShare('clipboard'),
      feedback: copiedToClipboard && 'Copied!'
    },
    {
      id: 'twitter',
      icon: Twitter,
      label: 'Share on Twitter',
      action: () => handleShare('twitter')
    },
    {
      id: 'instagram',
      icon: Instagram,
      label: 'Share on Instagram',
      action: () => handleShare('instagram')
    }
  ];

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      className="fixed inset-0 flex items-center justify-center z-50 px-4"
    >
      {/* Backdrop */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="absolute inset-0 bg-black/40 backdrop-blur-sm"
        onClick={onClose}
      />

      {/* Modal Content */}
      <motion.div
        initial={{ y: 20 }}
        animate={{ y: 0 }}
        className="bg-white/10 backdrop-blur-md rounded-2xl p-6 w-full max-w-sm relative"
      >
        <h3 className="text-white font-bold text-xl mb-4">Share Your Score</h3>
        
        <div className="space-y-3">
          {shareOptions.map((option) => (
            <button
              key={option.id}
              onClick={option.action}
              className="w-full flex items-center justify-between bg-white/10 hover:bg-white/20 rounded-xl p-4 text-white transition-colors"
            >
              <span className="flex items-center">
                <option.icon size={20} className="mr-3" />
                {option.label}
              </span>
              {option.feedback && (
                <motion.span
                  initial={{ opacity: 0, y: 5 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="text-green-400"
                >
                  {option.feedback}
                </motion.span>
              )}
            </button>
          ))}
        </div>

        {/* Preview */}
        <div className="mt-4 p-4 rounded-xl bg-white/5">
          <p className="text-white/80 text-sm">Preview:</p>
          <p className="text-white mt-2">
            Just scored {rizzScore} on my Rizz test! 🔥
            <br />
            Check out my conversation skills at rizzapp.com
          </p>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default ShareModal;