// LoadingScreen.jsx
import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const LoadingScreen = ({ status, message, onRetry }) => {
  const [calculationStage, setCalculationStage] = useState(0);
  const mountTime = useRef(Date.now());

  const debugLog = (message, data = {}) => {
    console.group(`🔄 LoadingScreen: ${message}`);
    console.log('Props:', { status, message, hasRetry: !!onRetry });
    if (Object.keys(data).length > 0) {
      console.log('Data:', data);
    }
    console.groupEnd();
  };

  useEffect(() => {
    debugLog('Screen mounted');
    
    // For calculating status, show progressive stages
    if (status === 'calculating') {
      const stageTimer = setInterval(() => {
        const timeElapsed = Date.now() - mountTime.current;
        // Only progress stages if we've been mounted for less than 30 seconds
        if (timeElapsed < 30000) {
          setCalculationStage(prev => (prev + 1) % 4);
        }
      }, 3000);

      return () => clearInterval(stageTimer);
    }
  }, [status]);

  const getCalculatingContent = () => {
    const stages = [
      {
        message: 'Analyzing conversation patterns...',
        subMessage: 'Evaluating your communication style'
      },
      {
        message: 'Measuring emotional intelligence...',
        subMessage: 'Assessing connection building'
      },
      {
        message: 'Calculating final score...',
        subMessage: 'Almost there!'
      },
      {
        message: 'Preparing your results...',
        subMessage: 'Just a moment longer'
      }
    ];

    return {
      icon: '🎲',
      title: 'Analyzing Your Game',
      ...stages[calculationStage]
    };
  };

  const getContent = () => {
    switch (status) {
      case 'initializing':
        return {
          icon: '🎤',
          title: 'Initializing Voice Chat',
          message: message || 'Setting up voice chat capabilities...',
          subMessage: 'This should only take a moment',
          showSpinner: true,
          showRetry: !!onRetry
        };
      case 'calculating':
        return getCalculatingContent();
      case 'connecting':
        return {
          icon: '🔄',
          title: 'Connecting',
          message: message || 'Establishing connection to AI service...',
          subMessage: 'This should only take a moment'
        };
      case 'error':
        return {
          icon: '⚠️',
          title: 'Connection Issue',
          message: 'Having trouble connecting to the AI service.',
          subMessage: 'Please check your internet connection'
        };
      case 'processing':
        return {
          icon: '💭',
          title: 'Processing Results',
          message: 'Almost there! Finalizing your score...',
          subMessage: 'Just a few more seconds'
        };
      default:
        return {
          icon: '⌛',
          title: 'Loading',
          message: message || 'Getting everything ready...',
          subMessage: 'Please wait'
        };
    }
  };

  const content = getContent();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="h-screen bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500 flex items-center justify-center p-6"
    >
      <div className="bg-white/10 backdrop-blur-sm rounded-xl p-8 text-white text-center max-w-md mx-4">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          className="text-5xl mb-6"
        >
          {content.icon}
        </motion.div>

        <motion.h2
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          className="text-2xl font-bold mb-4"
        >
          {content.title}
        </motion.h2>

        <div className="relative w-20 h-20 mx-auto mb-6">
          <div className="absolute inset-0 rounded-full border-4 border-white/20" />
          <div className="absolute inset-0 rounded-full border-4 border-white border-t-transparent animate-spin" />
          {status === 'calculating' && (
            <div 
              className="absolute inset-0 rounded-full border-4 border-white/50"
              style={{
                clipPath: `inset(0 ${100 - ((calculationStage + 1) * 25)}% 0 0)`
              }}
            />
          )}
        </div>

        <motion.p
          key={content.message}
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="mb-2 text-lg"
        >
          {content.message}
        </motion.p>

        <motion.p
          key={content.subMessage}
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.3 }}
          className="text-white/60 text-sm"
        >
          {content.subMessage}
        </motion.p>

        <AnimatePresence>
          {content.showRetry && onRetry && (
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={onRetry}
              className="mt-4 bg-white/20 hover:bg-white/30 transition-all transform hover:scale-105 rounded-full px-6 py-2 font-medium"
            >
              Try Again
            </motion.button>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default LoadingScreen;
