import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import { Mail, ArrowLeft, Loader } from 'lucide-react';

const VerificationPending = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email;
  const [isChecking, setIsChecking] = useState(true);
  const [checkCount, setCheckCount] = useState(0);

  useEffect(() => {
    let intervalId;

    const checkVerificationStatus = async () => {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_API_URL}/api/auth/verify-status?email=${email}`,
          {
            headers: {
              'Accept': 'application/json'
            }
          }
        );
        
        if (!response.ok) throw new Error('Failed to check status');
        
        const data = await response.json();
        console.log('Verification status:', data);
        
        if (data.isVerified) {
          clearInterval(intervalId);
          navigate('/login', { 
            state: { 
              message: 'Email verified successfully! You can now log in.',
              verifiedEmail: email 
            }
          });
        }
      } catch (error) {
        console.error('Error checking verification status:', error);
      } finally {
        setIsChecking(false);
        setCheckCount(prev => prev + 1);
      }
    };

    if (email) {
      checkVerificationStatus();
      intervalId = setInterval(checkVerificationStatus, 5000); // Check every 5 seconds
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [email, navigate]);

  if (!email) {
    return <Navigate to="/register" replace />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500 flex items-center justify-center p-4">
      <div className="bg-white/10 backdrop-blur-sm rounded-xl p-8 w-full max-w-md text-center">
        <div className="bg-white/20 rounded-full p-4 w-16 h-16 mx-auto mb-6 flex items-center justify-center">
          {isChecking ? (
            <Loader className="w-8 h-8 text-white animate-spin" />
          ) : (
            <Mail className="w-8 h-8 text-white" />
          )}
        </div>
        
        <h2 className="text-2xl font-bold text-white mb-4">
          Check your email
        </h2>
        
        <p className="text-white/80 mb-6">
          We've sent a verification link to:
          <br />
          <span className="font-semibold text-white">{email}</span>
        </p>
        
        <div className="bg-white/10 rounded-lg p-4 mb-6 text-sm text-white/70">
          <p>Please check your email and click the verification link to complete your registration.</p>
          {isChecking && (
            <p className="mt-2 text-white/60">
              Checking verification status...
            </p>
          )}
        </div>

        <div className="space-y-4">
          <button
            onClick={() => navigate('/login')}
            className="w-full bg-white/20 hover:bg-white/30 text-white py-3 rounded-lg transition-colors"
          >
            Return to Login
          </button>
          
          <button
            onClick={() => navigate('/register')}
            className="flex items-center justify-center w-full text-white/70 hover:text-white"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Register
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerificationPending;