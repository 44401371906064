export const VAPI_CONFIG = {
  transcriber: {
    provider: "deepgram",
    model: "nova-2",
    language: "en-US",
  },
  voice: {
    provider: "playht",
    voiceId: "jennifer",
  },
  model: {
    provider: "openrouter",
    model: "sao10k/l3.1-euryale-70b",
    temperature: 0.7,
    maxTokens: 150
  }
};

export const getVapiAssistantConfig = (character) => ({
  ...VAPI_CONFIG,
  name: character.name,
  firstMessage: "Hey there! Ready to chat?",
  model: {
    ...VAPI_CONFIG.model,
    messages: [
      {
        role: "system",
        content: character.systemPrompt,
      },
    ],
  },
}); 