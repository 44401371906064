import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeft, Trophy, Clock, Star, TrendingUp, ArrowUp } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useProgress } from '../contexts/ProgressContext';
import { useAuth } from '../contexts/AuthContext';

const Leaderboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [timeframe, setTimeframe] = useState('daily');
  const [category, setCategory] = useState('overall');
  const { stats } = useProgress();
  const { user, authenticatedFetch } = useAuth();
  const [leaderboardData, setLeaderboardData] = useState({
    daily: { overall: [], improvement: [], streak: [] },
    weekly: { overall: [], improvement: [], streak: [] },
    monthly: { overall: [], improvement: [], streak: [] }
  });
  const [userRank, setUserRank] = useState(null);
  const [loading, setLoading] = useState(true);

  const timeframes = [
    { id: 'daily', label: 'Daily', icon: Clock },
    { id: 'weekly', label: 'Weekly', icon: Star },
    { id: 'monthly', label: 'Monthly', icon: TrendingUp }
  ];

  const categories = [
    { id: 'overall', label: 'Top Rizz', icon: Trophy },
    { id: 'improvement', label: 'Most Improved', icon: ArrowUp },
    { id: 'streak', label: 'Longest Streak', icon: Star }
  ];

  const fetchLeaderboard = async () => {
    try {
      setLoading(true);
      const response = await authenticatedFetch(`/api/leaderboard?timeframe=${timeframe}&category=${category}`);
      const data = await response.json();
      
      // Transform the data to match our structure with safe value handling
      const transformedData = data.map((item, index) => {
        // Safely get the category value with fallback to 0
        const categoryValue = item[category] || 0;
        
        // Format the score based on category with safe number handling
        let formattedScore;
        if (category === 'streak') {
          formattedScore = `${categoryValue} days`;
        } else if (category === 'improvement') {
          formattedScore = `+${Number(categoryValue).toFixed(1)}`;
        } else {
          formattedScore = Number(categoryValue).toFixed(1);
        }
  
        return {
          rank: index + 1,
          name: item.name || 'Anonymous',
          score: formattedScore,
          change: 0,
          avatar: item.name || 'anonymous',
          userId: item.userId
        };
      });
  
      setLeaderboardData(prev => ({
        ...prev,
        [timeframe]: {
          ...prev[timeframe],
          [category]: transformedData
        }
      }));
  
      // Find user's rank if user exists
      if (user?.id) {
        const userPosition = transformedData.findIndex(item => item.userId === user.id);
        setUserRank(userPosition !== -1 ? {
          position: userPosition + 1,
          score: transformedData[userPosition].score,
          percentile: Math.round((transformedData.length - userPosition) / transformedData.length * 100)
        } : null);
      } else {
        setUserRank(null);
      }
  
    } catch (error) {
      console.error('Error fetching leaderboard:', error);
      // Set empty data for the current timeframe and category
      setLeaderboardData(prev => ({
        ...prev,
        [timeframe]: {
          ...prev[timeframe],
          [category]: []
        }
      }));
    } finally {
      setLoading(false);
    }
  };

  // Fetch when timeframe or category changes
  useEffect(() => {
    fetchLeaderboard();
  }, [timeframe, category, user?.id]);

  // Fetch when returning to the leaderboard screen
  useEffect(() => {
    const refreshLeaderboard = () => {
      if (document.visibilityState === 'visible') {
        fetchLeaderboard();
      }
    };

    document.addEventListener('visibilitychange', refreshLeaderboard);
    return () => {
      document.removeEventListener('visibilitychange', refreshLeaderboard);
    };
  }, [timeframe, category]);

  // Fetch when navigating back to this screen
  useEffect(() => {
    fetchLeaderboard();
  }, [location.key]);

  const LeaderboardItem = ({ item, index }) => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1 }}
      className="bg-white/10 backdrop-blur-sm rounded-xl p-4 flex items-center justify-between"
    >
      <div className="flex items-center space-x-4">
        <div className="w-8 h-8 flex items-center justify-center font-bold text-lg text-white">
          {item.rank}
        </div>
        <div className="h-10 w-10 rounded-full bg-gradient-to-r from-pink-500 to-indigo-500 flex items-center justify-center">
          <img
            src={`https://api.dicebear.com/6.x/avataaars/svg?seed=${item.avatar}`}
            alt={item.name}
            className="w-full h-full rounded-full"
          />
        </div>
        <div>
          <div className="text-white font-semibold">{item.name}</div>
          <div className="text-white/60 text-sm">Rank #{item.rank}</div>
        </div>
      </div>
      <div className="text-right">
        <div className="text-white font-bold">{item.score}</div>
        <div className={`text-sm ${item.change > 0 ? 'text-green-400' : item.change < 0 ? 'text-red-400' : 'text-white/60'}`}>
          {item.change > 0 ? '↑' : item.change < 0 ? '↓' : '–'} {Math.abs(item.change)}
        </div>
      </div>
    </motion.div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500 p-6">
      <div className="sticky top-0 z-10 -mx-6 -mt-6 px-6 pt-6 pb-4 bg-gradient-to-b from-indigo-600 to-indigo-600/80 backdrop-blur-sm">
        <button 
          onClick={() => navigate('/')}
          className="text-white/90 hover:text-white transition-colors mb-4 flex items-center"
        >
          <ArrowLeft size={24} className="mr-2" />
          <span>Back</span>
        </button>
        <h1 className="text-2xl font-bold text-white mb-4">Leaderboard</h1>
        
        {/* Timeframe Selector */}
        <div className="flex space-x-2 mb-4">
          {timeframes.map(tf => (
            <button
              key={tf.id}
              onClick={() => setTimeframe(tf.id)}
              className={`
                flex items-center px-4 py-2 rounded-full text-sm font-medium
                ${timeframe === tf.id ? 
                  'bg-white text-purple-600' : 
                  'bg-white/10 text-white hover:bg-white/20'
                }
              `}
            >
              <tf.icon size={16} className="mr-2" />
              {tf.label}
            </button>
          ))}
        </div>

        {/* Category Selector */}
        <div className="flex space-x-2">
          {categories.map(cat => (
            <button
              key={cat.id}
              onClick={() => setCategory(cat.id)}
              className={`
                flex items-center px-4 py-2 rounded-full text-sm font-medium
                ${category === cat.id ? 
                  'bg-white text-purple-600' : 
                  'bg-white/10 text-white hover:bg-white/20'
                }
              `}
            >
              <cat.icon size={16} className="mr-2" />
              {cat.label}
            </button>
          ))}
        </div>
      </div>

      {/* Leaderboard List */}
      <div className="mt-6 space-y-3">
        {loading ? (
          <div className="text-white text-center py-8">Loading...</div>
        ) : (
          <AnimatePresence>
            {leaderboardData[timeframe][category].map((item, index) => (
              <LeaderboardItem key={item.rank} item={item} index={index} />
            ))}
          </AnimatePresence>
        )}
      </div>

      {/* Your Ranking */}
      {userRank && (
        <div className="fixed bottom-6 left-6 right-6">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white/10 backdrop-blur-sm rounded-xl p-4 flex items-center justify-between"
          >
            <div className="flex items-center space-x-4">
              <div className="w-8 h-8 flex items-center justify-center font-bold text-lg text-white">
                {userRank.position}
              </div>
              <div className="h-10 w-10 rounded-full bg-gradient-to-r from-pink-500 to-indigo-500">
                <img
                  src={`https://api.dicebear.com/6.x/avataaars/svg?seed=${user?.name}`}
                  alt={user?.name}
                  className="w-full h-full rounded-full"
                />
              </div>
              <div className="text-white">
                <div className="font-semibold">You</div>
                <div className="text-white/60 text-sm">Top {userRank.percentile}%</div>
              </div>
            </div>
            <div className="text-right">
              <div className="text-white font-bold">{userRank.score}</div>
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default Leaderboard;
