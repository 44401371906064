import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { useApp } from '../contexts/AppContext';
import { motion } from 'framer-motion';

const GenderSelect = () => {
  const navigate = useNavigate();
  const { setSelectedGender } = useApp();

  const handleSelect = (gender) => {
    setSelectedGender(gender);
    navigate('/character');
  };

  const genderOptions = [
    {
      id: 'girls',
      label: 'Girls',
      emoji: '👩',
      gradient: 'from-pink-400 to-purple-500'
    },
    {
      id: 'guys',
      label: 'Guys',
      emoji: '👨',
      gradient: 'from-blue-400 to-indigo-500'
    }
  ];

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="h-screen bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500 p-6"
    >
      <button 
        onClick={() => navigate('/')}
        className="text-white/90 hover:text-white transition-colors mb-8 flex items-center"
      >
        <ArrowLeft size={24} className="mr-2" />
        <span>Back</span>
      </button>

      <h2 className="text-3xl font-bold text-white mb-8">I want to chat with...</h2>

      <div className="grid grid-cols-1 gap-4">
        {genderOptions.map((gender) => (
          <motion.button
            key={gender.id}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => handleSelect(gender.id)}
            className={`
              bg-white/10 backdrop-blur-lg rounded-xl p-6 
              flex items-center justify-between
              hover:bg-white/20 transition-all
              border border-white/10
            `}
          >
            <div className="flex items-center">
              <div className={`w-12 h-12 rounded-full bg-gradient-to-r ${gender.gradient} flex items-center justify-center text-2xl`}>
                {gender.emoji}
              </div>
              <span className="text-xl text-white ml-4 font-semibold">{gender.label}</span>
            </div>
            <ArrowRight className="text-white/80" size={24} />
          </motion.button>
        ))}
      </div>

      <div className="mt-8 bg-white/10 backdrop-blur-sm rounded-xl p-4">
        <p className="text-white/80 text-sm text-center">
          Choose who you'd like to practice your conversation skills with
        </p>
      </div>
    </motion.div>
  );
};

export default GenderSelect;