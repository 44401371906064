import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const result = await login(email, password);
      
      if (result.success) {
        const from = location.state?.from || '/gender';
        console.log('Login successful, redirecting to:', from);
        navigate(from, { replace: true });
      } else {
        setError(result.error || 'Login failed');
        if (result.error === 'Please verify your email first') {
          // Redirect to verification pending page
          navigate('/verification-pending', { 
            state: { email },
            replace: true 
          });
        }
      }
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message || 'Login failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="h-screen bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500 flex items-center justify-center">
      <form onSubmit={handleLogin} className="bg-white/10 backdrop-blur-sm p-6 rounded-xl max-w-sm w-full">
        <h2 className="text-2xl font-bold text-white mb-6 text-center">Login</h2>
        
        {error && (
          <div className="mb-4 p-3 bg-red-500/20 border border-red-500/50 rounded text-white text-sm">
            {error}
          </div>
        )}

        <div className="mb-4">
          <label className="block text-white mb-2" htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            className="w-full p-3 rounded-lg bg-white/20 text-white placeholder-white/70 focus:outline-none"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={isLoading}
          />
        </div>
        <div className="mb-6">
          <label className="block text-white mb-2" htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            className="w-full p-3 rounded-lg bg-white/20 text-white placeholder-white/70 focus:outline-none"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={isLoading}
          />
        </div>
        <button
          type="submit"
          disabled={isLoading}
          className={`w-full bg-indigo-500 hover:bg-indigo-600 text-white font-semibold py-3 rounded-lg transition duration-200 ${
            isLoading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isLoading ? 'Logging in...' : 'Login'}
        </button>
        <p className="text-white mt-4 text-center">
          Don't have an account? <a href="/register" className="underline">Register here</a>
        </p>
        <p className="text-white mt-2 text-center">
          <a href="/verification-pending" className="underline">Resend verification email</a>
        </p>
      </form>
    </div>
  );
};

export default Login;