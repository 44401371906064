import React, { useEffect } from 'react';
import { PaperAirplaneIcon } from '@heroicons/react/24/solid';

const ChatInput = ({
  inputText,
  setInputText,
  handleKeyPress,
  messageCount,
  isTyping,
  handleSendMessage,
  disabled,
}) => {
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      textarea.chat-input {
        -webkit-text-fill-color: white !important;
        color: white !important;
        background-color: rgba(255, 255, 255, 0.1) !important;
      }

      textarea.chat-input::placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
        -webkit-text-fill-color: rgba(255, 255, 255, 0.5) !important;
      }

      @supports (-webkit-touch-callout: none) {
        textarea.chat-input {
          -webkit-text-fill-color: white !important;
          color: white !important;
        }
      }
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  return (
    <div className="p-4 bg-white/10 backdrop-blur-sm">
      {messageCount !== undefined && (
        <div className="text-white/60 text-sm text-center mb-2">
          Messages: {messageCount}/14
        </div>
      )}
      
      <div className="relative flex items-center">
        <textarea
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder={disabled ? "Game ended" : "Type your message..."}
          disabled={disabled || isTyping}
          className={`chat-input w-full rounded-full py-3 px-4 pr-12 
            bg-transparent
            border border-white/20
            text-white placeholder-white/50 
            focus:outline-none focus:ring-2 focus:ring-white/30 
            resize-none overflow-hidden
            [appearance:none] 
            [-webkit-appearance:none]
            [color-scheme:dark]
            ${disabled || isTyping ? 'opacity-50 cursor-not-allowed' : ''}
            ${inputText.length > 0 ? 'pr-16' : ''}`}
          rows="1"
          style={{
            minHeight: '48px',
            maxHeight: '120px',
            WebkitTextFillColor: 'white',
            caretColor: 'white',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            color: 'white',
          }}
        />
        
        <button
          onClick={handleSendMessage}
          disabled={disabled || isTyping || !inputText.trim()}
          className={`absolute right-2 p-2 rounded-full transition-all
            ${inputText.trim() 
              ? 'bg-white/20 hover:bg-white/30 text-white' 
              : 'text-white/30'}
            ${disabled || isTyping ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
          `}
        >
          <PaperAirplaneIcon className="h-5 w-5" />
        </button>
      </div>

      {isTyping && (
        <div className="text-white/60 text-sm mt-2 text-center">
          AI is typing...
        </div>
      )}
    </div>
  );
};

export default ChatInput;