import React from 'react';
import { useApp } from '../contexts/AppContext';
import { motion } from 'framer-motion';

const TimeTrialHUD = ({ timeLeft }) => {
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  
  return (
    <motion.div 
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="absolute top-4 left-1/2 -translate-x-1/2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full"
    >
      <span className="text-white font-medium">
        {minutes}:{seconds.toString().padStart(2, '0')}
      </span>
    </motion.div>
  );
};

const LifeBarHUD = ({ lifeBar }) => (
  <motion.div 
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    className="absolute top-0 left-0 right-0 z-10"
  >
    <div className="p-2">
      <div className="relative h-1.5 bg-white/10 rounded-full overflow-hidden">
        <motion.div
          className="absolute top-0 left-0 h-full bg-gradient-to-r from-pink-500 to-indigo-500"
          initial={{ width: "100%" }}
          animate={{ width: `${lifeBar}%` }}
          transition={{ type: 'spring', damping: 10 }}
        />
      </div>
      <div className="absolute right-2 top-3 text-white/60 text-xs">
        {Math.round(lifeBar)}%
      </div>
    </div>
  </motion.div>
);

const ClassicHUD = ({ messageCount }) => {
  const remainingMessages = 14 - messageCount;
  
  return (
    <motion.div 
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="absolute top-4 left-1/2 -translate-x-1/2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full"
    >
      <span className="text-white/80 font-medium">
        {remainingMessages} {remainingMessages === 1 ? 'message' : 'messages'} left
      </span>
    </motion.div>
  );
};

const GameHUD = () => {
  const { gameMode, lifeBar, timeLeft, messageCount } = useApp();

  // Early return for invalid states
  if (!gameMode) return null;

  return (
    <>
      {gameMode === 'timeTrial' && <TimeTrialHUD timeLeft={timeLeft} />}
      {gameMode === 'lifeBar' && <LifeBarHUD lifeBar={lifeBar} />}
      {gameMode === 'classic' && <ClassicHUD messageCount={messageCount} />}
    </>
  );
};

export default GameHUD;