// src/components/CharacterSelect.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { useApp } from '../contexts/AppContext';
import { motion } from 'framer-motion';
import { characterPersonalities } from '../config/characters';

const CharacterSelect = () => {
  const navigate = useNavigate();
  const { selectedGender, setSelectedCharacter } = useApp();

  // Filter characters based on selectedGender
  const characters = Object.values(characterPersonalities).filter(
    (character) => {
      if (selectedGender === 'girls') {
        return character.gender === 'female';
      } else if (selectedGender === 'boys') {
        return character.gender === 'male';
      }
      return true; // Include all characters if gender is not specified
    }
  );

  const handleSelect = (character) => {
    setSelectedCharacter(character);
    navigate('/mode'); // Navigate to mode selection screen
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="h-screen bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500 p-6"
    >
      <button 
        onClick={() => navigate('/gender')}
        className="text-white/90 hover:text-white transition-colors mb-8 flex items-center"
      >
        <ArrowLeft size={24} className="mr-2" />
        <span>Back</span>
      </button>

      <h2 className="text-3xl font-bold text-white mb-6">Choose Your Date</h2>

      <div className="grid grid-cols-2 gap-4 mb-6">
        {characters.map((char) => (
          <motion.button
            key={char.id}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => handleSelect(char)}
            className="bg-white/10 backdrop-blur-lg rounded-xl p-4 flex flex-col items-center text-center hover:bg-white/20 transition-all border border-white/10"
          >
            <div className={`w-20 h-20 rounded-full bg-gradient-to-r ${char.gradient} mb-3`}>
              <img 
                src={`https://api.dicebear.com/6.x/avataaars/svg?seed=${char.name}`} 
                alt={char.name}
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <h3 className="text-white font-semibold mb-1">{char.name}</h3>
            <p className="text-white/80 text-sm mb-2">{char.type}</p>
            <div className="bg-white/10 rounded-full px-3 py-1">
              <span className="text-white/90 text-xs">{char.mood}</span>
            </div>
          </motion.button>
        ))}
      </div>
    </motion.div>
  );
};

export default CharacterSelect;