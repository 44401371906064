// useMessageHandling.js
import { useState, useRef, useCallback } from 'react';
import { useApp } from '../../../contexts/AppContext';
import { useAuth } from '../../../contexts/AuthContext';
import { AIService } from '../../../services/ai';

export function useMessageHandling(isGameActive) {
  const {
    selectedCharacter,
    messages,
    setMessages,
    messageCount,
    setMessageCount,
    gameMode,
    setIsGameActive,
    analyzeAndUpdateLifeBar,
  } = useApp();
  
  const { user } = useAuth();

  const [inputText, setInputText] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [error, setError] = useState(null);

  const startTime = useRef(Date.now());
  const messageIdRef = useRef(Date.now());
  const aiService = useRef(new AIService());

  const debugLog = (message, data = {}) => {
    console.group(`💬 useMessageHandling: ${message}`);
    console.log('State:', {
      isGameActive,
      messageCount,
      gameMode,
      isTyping,
      hasError: !!error
    });
    if (Object.keys(data).length > 0) {
      console.log('Data:', data);
    }
    console.groupEnd();
  };

  const getNextMessageId = useCallback(() => {
    messageIdRef.current += 1;
    return messageIdRef.current;
  }, []);

  const handleSendMessage = useCallback(async () => {
    if (!isGameActive || !inputText.trim()) return;

    const currentExchangeCount = Math.floor(messageCount / 2);
    
    if (gameMode === 'classic' && currentExchangeCount >= 6) {
      debugLog('Classic mode message limit reached', {
        messageCount,
        exchangeCount: currentExchangeCount
      });
      setError('Message limit reached');
      setIsGameActive(false);
      return;
    }

    setError(null);

    const userMessageId = getNextMessageId();
    const aiMessageId = getNextMessageId();

    const newUserMessage = {
      id: userMessageId,
      text: inputText.trim(),
      sender: 'user',
    };

    setMessages((prev) => [...prev, newUserMessage]);
    setInputText('');
    setIsTyping(true);

    try {
      let aiResponse = '';
      const userName = user?.name || 'there';

      await aiService.current.streamChat(
        [
          {
            role: 'system',
            content: `${selectedCharacter.systemPrompt}\n\nYou are talking to ${userName}. Remember their name and use it naturally in conversation when appropriate.`,
          },
          ...messages.map((msg) => ({
            role: msg.sender === 'user' ? 'user' : 'assistant',
            content: msg.text,
          })),
          {
            role: 'user',
            content: `${inputText} (Remember, you're talking to ${userName})`,
          },
        ],
        (token) => {
          aiResponse += token;
          setMessages((prev) => {
            const newMessages = [...prev];
            const lastMessage = newMessages[newMessages.length - 1];

            if (lastMessage?.sender === 'ai') {
              lastMessage.text = aiResponse;
              return newMessages;
            } else {
              return [
                ...newMessages,
                {
                  id: aiMessageId,
                  text: aiResponse,
                  sender: 'ai',
                  character: selectedCharacter.name,
                },
              ];
            }
          });
        }
      );

      // Increment message count after both user and AI messages
      const newCount = messageCount + 2;
      setMessageCount(newCount);

      // Analyze response for life bar mode
      const lastAiMessage =
        messages.length > 0 && messages[messages.length - 1].sender === 'ai'
          ? messages[messages.length - 1].text
          : null;

      if (gameMode === 'lifeBar' && lastAiMessage) {
        debugLog('Analyzing response for life bar', { lastAiMessage, userResponse: inputText.trim() });
        await analyzeAndUpdateLifeBar(lastAiMessage, inputText.trim());
      }

      // For classic mode, just update game state
      if (gameMode === 'classic' && Math.floor(newCount / 2) >= 7) {
        debugLog('Classic mode ending - exchange limit reached', {
          messageCount: newCount,
          exchangeCount: Math.floor(newCount / 2)
        });
        setIsGameActive(false);
      }

    } catch (error) {
      console.error('Error sending message:', error);
      setError('Failed to send message. Please try again.');
      setMessages((prev) => prev.slice(0, -1));
    } finally {
      setIsTyping(false);
    }
  }, [
    inputText,
    isGameActive,
    messages,
    messageCount,
    gameMode,
    selectedCharacter,
    analyzeAndUpdateLifeBar,
    getNextMessageId,
    setMessages,
    setMessageCount,
    user,
    setIsGameActive,
  ]);

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSendMessage();
      }
    },
    [handleSendMessage]
  );

  return {
    inputText,
    setInputText,
    isTyping,
    error,
    setError,
    handleSendMessage,
    handleKeyPress,
    messageCount,
  };
}

export default useMessageHandling;
