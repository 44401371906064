const getNativeModules = async () => {
  try {
    const modules = await Promise.allSettled([
      import('@capacitor/core'),
      import('@capacitor/app'),
      import('@capacitor/status-bar'),
      import('@capacitor/haptics'),
      import('@capacitor/keyboard')
    ]);

    return {
      Capacitor: modules[0].status === 'fulfilled' ? modules[0].value.Capacitor : null,
      App: modules[1].status === 'fulfilled' ? modules[1].value.App : null,
      StatusBar: modules[2].status === 'fulfilled' ? modules[2].value.StatusBar : null,
      Haptics: modules[3].status === 'fulfilled' ? modules[3].value.Haptics : null,
      Keyboard: modules[4].status === 'fulfilled' ? modules[4].value.Keyboard : null,
    };
  } catch (error) {
    console.warn('Failed to load native modules:', error);
    return null;
  }
};

const initializeNative = async () => {
  // Skip if not in native environment
  if (window.location.protocol !== 'capacitor:') {
    return {
      isNative: false,
      cleanup: () => {}
    };
  }

  try {
    const native = await getNativeModules();
    if (!native?.Capacitor?.isNativePlatform()) {
      throw new Error('Not a native platform');
    }

    const listeners = [];

    // Status Bar Setup
    if (native.StatusBar) {
      await native.StatusBar.setStyle({ style: 'dark' }).catch(console.warn);
      await native.StatusBar.setBackgroundColor({ color: '#4338ca' }).catch(console.warn);
    }

    // Back Button Handler
    if (native.App) {
      listeners.push(
        native.App.addListener('backButton', ({ canGoBack }) => {
          if (!canGoBack) {
            native.App.exitApp();
          } else {
            window.history.back();
          }
        })
      );

      // App State Handler
      listeners.push(
        native.App.addListener('appStateChange', ({ isActive }) => {
          console.log(isActive ? 'App became active' : 'App went to background');
        })
      );
    }

    // Keyboard Handlers
    if (native.Keyboard) {
      listeners.push(
        native.Keyboard.addListener('keyboardWillShow', () => {
          document.body.classList.add('keyboard-visible');
        })
      );

      listeners.push(
        native.Keyboard.addListener('keyboardWillHide', () => {
          document.body.classList.remove('keyboard-visible');
        })
      );
    }

    // Return API with native features
    return {
      isNative: true,
      triggerHapticFeedback: async () => {
        try {
          if (native.Haptics) {
            await native.Haptics.impact({ style: 'light' });
          }
        } catch (error) {
          console.warn('Haptic feedback failed:', error);
        }
      },
      cleanup: () => {
        listeners.forEach(listener => {
          try {
            listener.remove();
          } catch (error) {
            console.warn('Failed to remove listener:', error);
          }
        });
      }
    };
  } catch (error) {
    console.warn('Native initialization failed:', error);
    return {
      isNative: false,
      triggerHapticFeedback: () => {},
      cleanup: () => {}
    };
  }
};

export const capacitor = {
  initialize: initializeNative
};