// AppProvider.jsx
import React, { createContext, useContext, useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChatService } from '../services/ChatService';
import { ResponseAnalyzer } from '../services/ResponseAnalyzer';
import { aiService } from '../services/ai';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const navigate = useNavigate();
  // Base states
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messageCount, setMessageCount] = useState(0);
  const [rizzScore, setRizzScore] = useState(null);
  const [currentAnalysis, setCurrentAnalysis] = useState(null);

  // Game states
  const [chatMode, setChatMode] = useState('text');
  const [gameMode, setGameMode] = useState('classic');
  const [lifeBar, setLifeBar] = useState(100);
  const [timeLeft, setTimeLeft] = useState(180);
  const [isGameActive, setIsGameActive] = useState(false);
  const [isCalculatingScore, setIsCalculatingScore] = useState(false);

  // Refs
  const gameEndInProgress = useRef(false);
  const chatService = useRef(new ChatService(aiService));
  const responseAnalyzer = useRef(new ResponseAnalyzer(aiService));

  const debugLog = (message, data = {}) => {
    console.group(`🌍 AppContext: ${message}`);
    console.log('Current State:', {
      isGameActive,
      isCalculatingScore,
      rizzScore,
      messageCount,
      gameMode,
      lifeBar,
      gameEndInProgress: gameEndInProgress.current,
      hasCharacter: !!selectedCharacter
    });
    console.log('Updates:', data);
    console.groupEnd();
  };

  // Update game state function
  const updateGameState = useCallback(async (updates) => {
    debugLog('Updating game state', updates);
    
    try {
      const batchedUpdates = Object.entries(updates);
      const promises = batchedUpdates.map(([key, value]) => {
        return new Promise(resolve => {
          switch (key) {
            case 'rizzScore':
              setRizzScore(value);
              break;
            case 'currentAnalysis':
              setCurrentAnalysis(value);
              break;
            case 'isGameActive':
              setIsGameActive(value);
              break;
            case 'messages':
              setMessages(value);
              break;
            case 'messageCount':
              setMessageCount(value);
              break;
            case 'lifeBar':
              setLifeBar(value);
              break;
            case 'timeLeft':
              setTimeLeft(value);
              break;
            case 'isCalculatingScore':
              setIsCalculatingScore(value);
              break;
            default:
              break;
          }
          resolve();
        });
      });

      await Promise.all(promises);
      debugLog('State update complete', updates);
    } catch (error) {
      console.error('Error updating game state:', error);
      throw error;
    }
  }, []);

  const analyzeAndUpdateLifeBar = useCallback(async (aiMessage, userResponse) => {
    if (gameMode !== 'lifeBar' || !isGameActive || gameEndInProgress.current) return;
    
    try {
      const responseQuality = await responseAnalyzer.current.analyzeResponse(
        aiMessage,
        userResponse
      );

      setLifeBar((prev) => {
        const newValue = responseQuality === 'good' ? Math.min(100, prev + 4) :
                        responseQuality === 'bad' ? Math.max(0, prev - 8) :
                        prev;
        return newValue;
      });
    } catch (error) {
      console.error('Response analysis error:', error);
    }
  }, [gameMode, isGameActive]);

  const handleGameEnd = useCallback(async () => {
    if (gameEndInProgress.current) {
      debugLog('Game end already in progress, skipping');
      return;
    }

    debugLog('Starting game end process');
    try {
      gameEndInProgress.current = true;
      await updateGameState({
        isGameActive: false,
        isCalculatingScore: true
      });

      const finalAnalysis = await chatService.current.calculateRizzScore(messages);
      debugLog('Score calculated', { finalAnalysis });

      await updateGameState({
        isCalculatingScore: false,
        currentAnalysis: finalAnalysis,
        rizzScore: finalAnalysis.score
      });

      debugLog('Navigating to score screen');
      navigate('/score', { replace: true });
    } catch (error) {
      console.error('Game end error:', error);
      await updateGameState({
        isCalculatingScore: false,
        isGameActive: false
      });
    } finally {
      gameEndInProgress.current = false;
    }
  }, [messages, updateGameState, navigate]);

  // Reset game function
  const resetGame = useCallback(() => {
    debugLog('Resetting game state');
    updateGameState({
      messages: [],
      messageCount: 0,
      rizzScore: null,
      currentAnalysis: null,
      lifeBar: 100,
      timeLeft: 180,
      isGameActive: true,
      isCalculatingScore: false,
    });
    setSelectedCharacter(null);
    setSelectedGender(null);
    gameEndInProgress.current = false;
  }, [updateGameState]);

  // Game end conditions effect
  useEffect(() => {
    const shouldEndGame = (
      (gameMode === 'lifeBar' && lifeBar <= 0.1 && isGameActive) ||
      (gameMode === 'classic' && !isGameActive && messages.length > 0) ||
      (gameMode === 'timeTrial' && timeLeft <= 0 && isGameActive)
    );

    if (shouldEndGame && !gameEndInProgress.current && !isCalculatingScore) {
      debugLog('Game end condition met', { gameMode, lifeBar, isGameActive, messageCount: messages.length });
      handleGameEnd();
    }
  }, [gameMode, lifeBar, isGameActive, messages.length, timeLeft, isCalculatingScore, handleGameEnd]);

  // Memoize context value
  const value = useMemo(
    () => ({
      // Base states
      selectedGender,
      setSelectedGender,
      selectedCharacter,
      setSelectedCharacter,
      messages,
      setMessages,
      messageCount,
      setMessageCount,
      rizzScore,
      setRizzScore,
      currentAnalysis,
      setCurrentAnalysis,

      // Game states
      chatMode,
      setChatMode,
      gameMode,
      setGameMode,
      lifeBar,
      setLifeBar,
      timeLeft,
      setTimeLeft,
      isGameActive,
      setIsGameActive,
      isCalculatingScore,
      setIsCalculatingScore,

      // Methods
      updateGameState,
      resetGame,
      analyzeAndUpdateLifeBar,
      handleGameEnd
    }),
    [
      selectedGender,
      selectedCharacter,
      messages,
      messageCount,
      rizzScore,
      currentAnalysis,
      chatMode,
      gameMode,
      lifeBar,
      timeLeft,
      isGameActive,
      isCalculatingScore,
      updateGameState,
      resetGame,
      analyzeAndUpdateLifeBar,
      handleGameEnd
    ]
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useApp must be used within an AppProvider');
  }
  return context;
};

export default AppProvider;
